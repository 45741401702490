import React from "react";
import { Switch } from "@headlessui/react";

const ToggleSwitch = (props) => {
  return (
    <Switch
      checked={props.isChecked}
      onChange={() => props.onChange(!props.isChecked)}
      className={`${
        props.isChecked ? "bg-blue-600" : "bg-gray-500"
      } relative inline-flex items-center h-7 rounded-full w-14`}
    >
      <span className="sr-only">Enable</span>
      <span
        className={`${
          props.isChecked ? "translate-x-8" : "translate-x-0"
        } inline-block w-6 h-6 transform bg-white rounded-full`}
      />
    </Switch>
  );
};
export default ToggleSwitch;
