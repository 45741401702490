import React, { useState, useEffect, lazy } from "react";
import { useParams } from "react-router-dom";
import User_Receiver from "../context/user_context/User_Receiver";
import Settings_Receiver from "../context/settings_context/Settings_Receiver";
import ChainedContext from "../context/chainedContext";
import { FaWaze, FaGoogle, FaApple } from "react-icons/fa";
import { Dialog, Transition } from "@headlessui/react";
import api from "../api";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { GiCancel, GiSpeaker } from "react-icons/gi";
const DataField = lazy(() => import("../components/DataField"));
const Header = lazy(() => import("../components/Header"));

function Event({ user, history }) {
  const [event, setEvent] = useState({});
  const [speaking, setSpeaking] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [responses, setResponses] = useState({
    going: [],
    notGoing: [],
  });
  const synthesis = window.speechSynthesis;
  const params = useParams("event_id");
  useEffect(() => {
    getData();
    if (event.start) {
      if (dayjs(event.start) < dayjs()) {
        setDisabled(true);
      }
    }
  }, []);

  useEffect(() => {
    let responses = {
      going: 0,
      notGoing: 0,
    };
    if (event.responses && event.responses.length > 0) {
      responses.going = event.responses.filter((resp) => {
        return resp.response === "going";
      });
      responses.notGoing = event.responses.filter((resp) => {
        return resp.response === "not going";
      });
      setResponses(responses);
    }
  }, [event]);
  const getData = () => {
    api.events
      .show(params.event_id)
      .then((res) => {
        setEvent(res);
        if (res.location && res.location.geoJSON && res.location.geoJSON.geometry) {
          setCoordinates(res.location.geoJSON.geometry.coordinates);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const go = (data) => {
    history.push(`/team/${data}`);
  };
  const setUserResponse = (resp) => {
    let data = {};
    if (event) {
      if (event.class === "course") {
        data = {
          memberID: user.centralId,
          courseID: event._id,
          response: resp,
        };
        api.events.responses
          .add(event._id, data)
          .then(() => {
            getData();
            toast.success("Response Changed");
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        data = {
          memberID: user.centralId,
          eventID: event._id,
          response: resp,
        };
        api.events.responses
          .add(event._id, data)
          .then(() => {
            getData();
            toast.success("Response Changed");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };
  const speak = () => {
    if (synthesis.speaking) {
      synthesis.cancel();
      setSpeaking(false);
      return;
    }
    setSpeaking(true);
    const start = dayjs(event.start).format("DD MMMM YYYY") + " at " + dayjs(event.start).format("HH:mm");
    const location = event.location && event.location.geoJSON && event.location.geoJSON.properties.name;
    const data = event.title + "," + start + ", " + event.details + ". Location " + location;
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(data);
      synthesis.speak(utterance);
    } else {
      console.log("Text-to-speech not supported.");
    }
    if (synthesis.paused) {
      setSpeaking(false);
    }
  };
  const locationDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed z-10 inset-0 overflow-y-auto ">
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <div className="border-4 border-gray-700 inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <div className="text-3xl border-b border-gray-700 mb-4">
                <Dialog.Title>{event.location && event.location.geoJSON && event.location.geoJSON.properties && event.location.geoJSON.properties.name}</Dialog.Title>
              </div>

              {coordinates.length > 0 && (
                <div className="flex flex-col gap-4 text-xl">
                  <div className="">
                    <a
                      className="flex p-2 items-center gap-2 border border-gray-600 rounded-xl hover:bg-gray-700"
                      //   style={{ background: "#00ffff", width: "80%" }}
                      href={`https://www.waze.com/ul?ll=${coordinates[1]}%2C${coordinates[0]}&navigate=yes&zoom=17`}
                      target="_blank"
                    >
                      <FaWaze /> WAZE
                    </a>
                  </div>
                  <div className="">
                    <a
                      //   style={{ width: "80%" }}
                      className="flex p-2 items-center gap-2 border border-gray-600 rounded-xl hover:bg-gray-700"
                      href={`https://www.google.com/maps/dir/?api=1&destination=${coordinates[1]},${coordinates[0]}`}
                      target="_blank"
                    >
                      <FaGoogle /> Google Maps
                    </a>
                  </div>
                  <div className="">
                    <a
                      className="flex p-2 items-center gap-2 border border-gray-600 rounded-xl hover:bg-gray-700"
                      href={`http://maps.apple.com/?daddr=${coordinates[1]},${coordinates[0]}`}
                      target="_blank"
                    >
                      <FaApple /> Apple Maps
                    </a>
                  </div>
                </div>
              )}

              <div className="text-center mt-4 border-t border-gray-700 pt-4">
                <button aria-label="Close" className="px-4 py-2 bg-blue-600 rounded-lg" onClick={() => setIsOpen(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  console.log(event);
  return (
    <div className="w-full ">
      <Header title={event.title} sub={event.type} titleColour="text-white" />
      {locationDialog()}
      <div className="flex w-full ">
        <DataField label="Start Time" data={dayjs(event.start).format("DD-MM-YY HH:mm")} />
        <DataField label="End Time" data={dayjs(event.end).format("DD-MM-YY HH:mm")} />
      </div>
      <DataField action={() => go(event.organiser._id)} label="Organiser" data={event.organiser && event.organiser.name} />
      <DataField action={() => setIsOpen(true)} label="Location" data={event.location && event.location.geoJSON && event.location.geoJSON.properties.name} />
      <div onClick={speak}>
        <DataField
          label="Details"
          data={event.details}
          speaking={
            speaking ? <GiCancel size={"1.4rem"} className="ml-2  cursor-pointer text-red-300" /> : <GiSpeaker size={"1.4rem"} className="ml-2  cursor-pointer text-gray-300" />
          }
          speak
        />
      </div>
      <div className="flex  w-full ">
        <DataField
          label="Your Response"
          data={event.yourResponse ? (event.yourResponse.response === "going" ? "Going" : "Not Going") : "Not Responded"}
          labelColour={event.yourResponse ? (event.yourResponse.response === "going" ? " text-green-400  " : " text-red-400 ") : "text-primary"}
          colour={event.yourResponse ? (event.yourResponse.response === "going" ? "bg-green-600 bg-opacity-50 " : "bg-red-500 bg-opacity-50 ") : "bg-gray-900"}
        />
        <DataField
          label="Change to"
          data={
            dayjs(event.start).isAfter(dayjs()) ? (
              event.yourResponse ? (
                event.yourResponse.response === "going" ? (
                  <button aria-label="Not Going" className="bg-red-500 rounded-xl text-white p-2" onClick={() => setUserResponse("not going")} disabled={disabled}>
                    Not Going
                  </button>
                ) : (
                  <button aria-label="Going" className="bg-green-500 rounded-xl text-white p-2" onClick={() => setUserResponse("going")} disabled={disabled}>
                    Going
                  </button>
                )
              ) : event.canSignUp ? (
                <div className="flex gap-2 justify-center">
                  <button aria-label="Not Going" className="bg-red-500 rounded-xl text-white p-2" onClick={() => setUserResponse("not going")} disabled={disabled}>
                    Not Going
                  </button>
                  <button aria-label="Going" className="bg-green-500 rounded-xl text-white p-2" onClick={() => setUserResponse("going")} disabled={disabled}>
                    Going
                  </button>
                </div>
              ) : (
                <div className="text-sm">Contact organiser to change</div>
              )
            ) : (
              "--"
            )
          }
        />
      </div>

      <div className="p-2">
        <div className="text-gray-400 tracking-widest uppercase text-center text-2xl py-2">Responses</div>
        <div className="text-gray-400 bg-green-900 tracking-widest uppercase text-center text-xl py-2 border-b border-gray-700">Going ({responses.going.length})</div>
        {responses &&
          responses.going &&
          responses.going.map((r) => {
            // console.log(r);
            return (
              <div key={r._id} onClick={() => history.push(`/team/${r.member._id}`)} className="p-2 border-b border-gray-700 text-lg cursor-pointer">
                {r.member ? (
                  r.member.name
                ) : r.recruit ? (
                  <span>
                    {r.recruit.name} <span className="italic text-sm">(Recruit)</span>
                  </span>
                ) : (
                  "--"
                )}
              </div>
            );
          })}
        <div className="text-gray-400 bg-amber-900 tracking-widest uppercase text-center text-xl py-2 border-b border-gray-700">Not Going ({responses.notGoing.length})</div>
        {responses &&
          responses.notGoing &&
          responses.notGoing.map((r) => {
            return (
              <div key={r._id} onClick={() => history.push(`/team/${r.member._id}`)} className="p-2 border-b border-gray-700 text-lg cursor-pointer">
                {r.member ? (
                  r.member.name
                ) : r.recruit ? (
                  <span>
                    {r.recruit.name} <span className="italic text-sm">(Recruit)</span>
                  </span>
                ) : (
                  "--"
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default ChainedContext(Event, [
  [
    User_Receiver,
    (user) => {
      return { user };
    },
  ],
  [
    Settings_Receiver,
    (settings) => {
      return { settings };
    },
  ],
]);
