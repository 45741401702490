import React from "react";
import { GiCancel, GiSpeaker } from "react-icons/gi";
const DataField = ({
  label,
  data,
  colour,
  left,
  headerLeft,
  labelColour = "text-primary",
  textColour,
  action,
  speak,
  speaking,
}) => {
  return (
    <div
      className={`pb-2  flex-1 border border-gray-700	${colour}	${
        action && "currsor-pointer"
      }`}
    >
      <dt
        className={`flex text-lg pb-2 pt-2 tracking-widest ${labelColour}  bg-gray-900 bg-opacity-60 text-mono border-b border-gray-800 ${
          headerLeft ? "justify-start" : "justify-center"
        }`}
      >
        {label}
        {speaking}
      </dt>
      <dd
        onClick={action}
        className={`mt-1 text-xl  sm:mt-0 pt-2 ${
          left ? "text-left" : "text-center"
        } px-2 items-center ${textColour} `}
      >
        {/* {action && "currsor-pointer"} */}
        {data ? data : "--"}
      </dd>
    </div>
  );
};
export default DataField;
