import React, { useState, useEffect, lazy } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import api from "../api";
import { PencilIcon, XCircleIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import VehiclePhoto from "../components/vehiclePhoto";

const Header = lazy(() => import("../components/Header"));
const DataField = lazy(() => import("../components/DataField"));

export default function VehicleDetails({ history }) {
  const [vehicle, setVehicle] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const params = useParams("vehicle_id");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.vehicles.show(params.vehicle_id).then((res) => {
      setVehicle(res);
    });
  };
  const archiveVehicle = () => {
    api.vehicles.archive(params.vehicle_id, { archive: true }).then(() => {
      toast.success("Vehicle deleted!");
      history.push(`/you`);
    });
  };
  const archiveDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-4 border-red-700 inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <div className="text-3xl border-b border-gray-700 mb-4">
                <Dialog.Title>Delete Vehicle</Dialog.Title>
              </div>

              <div className="">
                Are you sure you want to delete {vehicle.make} {vehicle.model}{" "}
                {vehicle.regNumber} ?
              </div>
              <div className="text-red-400 italic text-center">
                This is not reversible
              </div>

              <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
                <button
                  aria-label="No"
                  className="px-2 py-2 bg-red-600 rounded-lg"
                  onClick={() => setIsOpen(false)}
                >
                  No
                </button>
                <button
                  aria-label="Yes"
                  className="px-2 py-2 bg-blue-600 rounded-lg"
                  onClick={archiveVehicle}
                >
                  Yes please delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  // console.log(vehicle);
  return (
    <div className="w-full">
      {archiveDialog()}
      <Header title={vehicle.make + " " + vehicle.model} />
      <div className="w-full text-center bg-gray-900 p-2 flex justify-around">
        <button
          aria-label="Delete"
          className="flex items-middle justify-center bg-gray-800 py-2 px-4 mx-auto border border-gray-700 rounded-xl text-xl"
          onClick={() => setIsOpen(true)}
        >
          <XCircleIcon className="h-6 text-red-500 mr-2" /> Delete
        </button>
        <button
          aria-label="Update"
          className="flex items-middle justify-center bg-gray-800 py-2 px-4 mx-auto border border-gray-700 rounded-xl text-xl"
          onClick={() => history.push(`/vehicle/${vehicle._id}/edit`)}
        >
          <PencilIcon className="h-6 text-blue-500 mr-2" /> Update
        </button>
      </div>

      <DataField
        textColour={" text-3xl tracking-widest"}
        label="Registration Number"
        data={vehicle.regNumber}
      />
      <div className="flex">
        <DataField label="Seats" data={vehicle.seats} />
        <DataField label="Doors" data={vehicle.doors} />
      </div>

      <div className="flex">
        <DataField label="Tow Weight" data={vehicle.towWeight} />
        <DataField label="Tyres" data={vehicle.tyres} />
      </div>
      <div className="flex">
        <DataField label="Colour" data={vehicle.colour} />
        <DataField
          label="Insurance From"
          data={dayjs(vehicle.insurance).format("DD-MM-YY")}
        />
      </div>
      <div
        className={`text-2xl text-gray-400 pb-2 pt-2 tracking-widestbg-gray-900 bg-opacity-60 text-mono border-b border-gray-800 text-center`}
      >
        DVLA
      </div>
      {vehicle.dvla && (
        <>
          <div className="flex">
            <DataField
              label="Tax Due"
              data={dayjs(vehicle.dvla.taxDueDate).format("DD-MM-YY")}
            />
            <DataField
              label="MoT Expires"
              data={dayjs(vehicle.dvla.motExpiryDate).format("DD-MM-YY")}
            />
          </div>
          <div className="flex">
            <DataField label="Co2 Emissions" data={vehicle.dvla.co2Emissions} />
            <DataField
              label="Engine Capacity"
              data={vehicle.dvla.engineCapacity}
            />
          </div>
        </>
      )}

      <div
        className={`text-2xl text-gray-400 pb-2 pt-2 tracking-widestbg-gray-900 bg-opacity-60 text-mono border-b border-gray-800 text-center`}
      >
        Photo
      </div>
      <div className="sm:m-2">
        {vehicle._id && <VehiclePhoto vehicle={vehicle} className="mx-auto border-4 border-white shadow-xl" />}
      </div>
      <div className={`pb-2  flex-1 border-t border-b border-gray-700	`}>
        <div
          className={`text-2xl text-gray-400 pb-2 pt-2 tracking-widestbg-gray-900 bg-opacity-60 text-mono border-b border-gray-800 text-center`}
        >
          Equipment
        </div>
        <div className="m-2 flex flex-wrap gap-2">
          {vehicle.kit && vehicle.kit.length > 0 ? (
            vehicle.kit.map((r, i) => {
              return (
                <div
                  key={i}
                  className="p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
                >
                  {r}
                </div>
              );
            })
          ) : (
            <div className={"w-full text-center text-gray-500 p-2"}>No kit</div>
          )}
        </div>
      </div>
    </div>
  );
}
