import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import User_Receiver from "../context/user_context/User_Receiver";
import Settings_Receiver from "../context/settings_context/Settings_Receiver";
import ChainedContext from "../context/chainedContext";
// import DataLine from "../components/DataLine";
import Select2 from "../components/Select2";
import Header from "../components/Header";
import DatePicker from "react-datepicker";
import api from "../api";
import toast from "react-hot-toast";

import DataField from "../components/DataField";

const tyreList = [
  { key: 1, text: "Road", value: "Road" },
  { key: 2, text: "Mud", value: "Mud" },
  { key: 3, text: "Mud/Snow", value: "Mud/Snow" },
  { key: 4, text: "All Terrain", value: "All Terrain" },
];

function AddVehicle({ user, history }) {
  const [data, setData] = useState({
    make: "",
    model: "",

    seats: "",
    tyres: "",
    doors: "",
    insurance: "",
    towWeight: "",
    colour: "",
    regNumber: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [inputDisabled, setInputDisabled] = useState(false);

  useEffect(() => {}, []);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setDisabled(false);
  };

  const handleSubmit = () => {
    let formData = data;
    formData.member = user._id;
    console.log(formData);
    api.vehicles.add(formData).then(() => {
      toast.success("Vehicle added");
      history.push(`/you`);
    });
  };
  // console.log(data);
  return (
    <div className="w-full pt-2">
      <Header title={`Add Vehicle `} />

      <div className="flex pt-2">
        <DataField
          label="Make"
          data={
            <input
              disabled={inputDisabled}
              name="make"
              value={data.make}
              autoComplete="off"
              placeholder="land rover, toyota..."
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
        <DataField
          label="Model"
          data={
            <input
              disabled={inputDisabled}
              name="model"
              value={data.model}
              autoComplete="off"
              placeholder="defender, supra..."
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
      </div>
      <div className="flex pt-2">
        <DataField
          label="Registration"
          data={
            <input
              disabled={inputDisabled}
              name="regNumber"
              value={data.regNumber}
              autoComplete="off"
              placeholder="abc123"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
      </div>
      <div className="flex pt-2">
        <DataField
          label="Seats"
          data={
            <input
              disabled={inputDisabled}
              name="seats"
              value={data.seats}
              autoComplete="off"
              placeholder="num seats"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
        <DataField
          label="Doors"
          data={
            <input
              disabled={inputDisabled}
              name="doors"
              value={data.doors}
              autoComplete="off"
              placeholder="num doors"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
      </div>
      <div className="flex pt-2">
        <DataField
          label="Tow Weight"
          data={
            <input
              disabled={inputDisabled}
              name="towWeight"
              value={data.towWeight}
              autoComplete="off"
              placeholder="tow weight"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
        <DataField
          label="Tyres"
          data={
            <Select2
              field={"tyres"}
              value={data.tyres}
              onChange={onChange}
              options={tyreList ? tyreList : []}
              disabled={inputDisabled}
            />
          }
        />
      </div>
      <div className="flex pt-2">
        <DataField
          label="Colour"
          data={
            <input
              disabled={inputDisabled}
              name="colour"
              value={data.colour}
              autoComplete="off"
              placeholder="colour"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />

        <DataField
          label="Insurance From"
          data={
            <DatePicker
              onChange={(date) => {
                setData({ ...data, insurance: date });
                setDisabled(false);
              }}
              selected={data.insurance ? new Date(data.insurance) : ""}
              // showTimeSelect
              // timeFormat="HH:mm"
              // timeIntervals={5}
              // showTimeInput
              // shouldCloseOnSelect={false}
              // timeInputLabel="Time:"
              dateFormat="dd-MM-yy"
              maxDate={new Date()}
              nextMonthButtonLabel=">"
              previousMonthButtonLabel="<"
              className="datePicker bg-gray-900 p-2 w-full rounded-xl border border-gray-700"
              data-disable-touch-keyboard
              disabled={inputDisabled}
            />
          }
        />
      </div>
      <div className="p-2"></div>
      <div className="p-2 text-center">
        <button
          aria-label="Add Vehicle"
          disabled={disabled}
          onClick={handleSubmit}
          className={`${
            disabled && " bg-opacity-30 border-green-900 text-green-900"
          } w-full p-2 bg-green-800 border border-green-500 rounded-xl`}
        >
          Add vehicle
        </button>
      </div>
    </div>
  );
}
export default ChainedContext(AddVehicle, [
  [
    User_Receiver,
    (user) => {
      return { user };
    },
  ],
  [
    Settings_Receiver,
    (settings) => {
      return { settings };
    },
  ],
]);
