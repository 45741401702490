import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, FilterIcon } from "@heroicons/react/solid";

export default function FilterComponent({
  sort,
  handleSort,
  filters,
  handleFilters,
}) {
  const [filterQuery, setFilterQuery] = useState({});
  const [filterloading, setFilterLoading] = useState(false);

  const [sortQuery, setSortQuery] = useState("");
  const [sortloading, setSortLoading] = useState(false);

  useEffect(() => {
    if (sortQuery !== undefined && handleSort && sortloading) {
      handleSort(sortQuery);
      setSortLoading(false);
    }
  }, [sortQuery, handleSort, sortloading]);

  useEffect(() => {
    if (filterQuery !== undefined && handleFilters && filterloading) {
      handleFilters(filterQuery);
      setFilterLoading(false);
    }
  }, [filterQuery, handleFilters, filterloading]);

  return (
    <div className="bg-gray-800">
      <Disclosure
        as="section"
        aria-labelledby="filter-heading"
        className="relative border-t border-b border-gray-700 grid items-center"
      >
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="relative col-start-1 row-start-1 py-4">
          <div className="max-w-7xl mx-auto flex space-x-6 divide-x divide-gray-700 text-sm px-4 sm:px-6 lg:px-8">
            <div>
              <Disclosure.Button className="group text-gray-400 font-medium flex items-center hover:text-gray-300">
                <FilterIcon
                  className="flex-none w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-300"
                  aria-hidden="true"
                />
                {filterQuery ? Object.keys(filterQuery).length : 0} Filter
                {filters && filters.length > 1 ? "s" : ""}
              </Disclosure.Button>
            </div>
            <div className="pl-6">
              <button
                onClick={() => {
                  setFilterQuery({});
                  setSortQuery("");
                  setSortLoading(true);
                  setFilterLoading(true);
                }}
                type="button"
                className="text-gray-400 hover:text-gray-300"
              >
                Clear All
              </button>
            </div>
          </div>
        </div>
        <Disclosure.Panel className="border-t border-gray-700 py-4">
          <div className="max-w-7xl mx-auto grid grid-cols-1 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            {filters !== undefined &&
            Array.isArray(filters) === true &&
            filters.length > 0 ? (
              filters.map((filter_item, index) => (
                <div
                  key={index}
                  className={`${
                    index > 0 ? "mt-6" : ""
                  } flex gap-y-10 auto-rows-min md:grid-cols-2 md:gap-x-6`}
                >
                  <fieldset>
                    <legend className="block font-xl mb-2">
                      {filter_item.title}
                    </legend>
                    <div className="flex flex-wrap gap-4">
                      {filter_item.options &&
                      Array.isArray(filter_item.options) === true &&
                      filter_item.options.length > 0 ? (
                        filter_item.options.map((option_item, option_index) => (
                          <div
                            key={option_index}
                            className="flex items-center text-base sm:text-sm"
                          >
                            {option_item.text && (
                              <button
                                className={`px-2 py-1 rounded-xl border ${
                                  filterQuery[filter_item.field] ===
                                  option_item.value
                                    ? " bg-green-700 border-green-400 "
                                    : " bg-gray-600 border-gray-400"
                                }`}
                                onClick={() => {
                                  if (
                                    filterQuery[filter_item.field] !==
                                      undefined &&
                                    filterQuery[filter_item.field] ===
                                      option_item.value
                                  ) {
                                    let filterQueryMod = { ...filterQuery };

                                    delete filterQueryMod[filter_item.field];

                                    setFilterQuery(filterQueryMod);
                                    setFilterLoading(true);
                                  } else {
                                    setFilterQuery({
                                      ...filterQuery,
                                      [filter_item.field]: option_item.value,
                                    });
                                    setFilterLoading(true);
                                  }
                                }}
                              >
                                {option_item.text}
                              </button>
                            )}
                            {/* <>
                            <input
                              // readOnly
                              name={filter_item.field}
                              type="radio"
                              checked={
                                filterQuery[filter_item.field] ===
                                option_item.value
                                  ? true
                                  : false
                              }
                              onChange={() => {}}
                              className="flex-shrink-0 h-4 w-4 border-gray-700 rounded text-indigo-600 focus:ring-indigo-500"
                              onClick={() => {
                                if (
                                  filterQuery[filter_item.field] !==
                                    undefined &&
                                  filterQuery[filter_item.field] ===
                                    option_item.value
                                ) {
                                  let filterQueryMod = { ...filterQuery };

                                  delete filterQueryMod[filter_item.field];

                                  setFilterQuery(filterQueryMod);
                                  setFilterLoading(true);
                                } else {
                                  setFilterQuery({
                                    ...filterQuery,
                                    [filter_item.field]: option_item.value,
                                  });
                                  setFilterLoading(true);
                                }
                              }}
                            />
                            <label
                              htmlFor={filter_item.field}
                              className="ml-3 min-w-0 flex-1 text-gray-200"
                            >
                              {option_item.text}
                            </label>
                            </> */}
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </fieldset>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </Disclosure.Panel>
        {sort !== undefined &&
        Array.isArray(sort) === true &&
        sort.length > 0 ? (
          <div className="col-start-1 row-start-1 py-4">
            <div className="flex justify-end max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <Menu as="div" className="relative inline-block">
                <div className="flex">
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-400 hover:text-gray-300">
                    Sort
                    <ChevronDownIcon
                      className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-300"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className={`origin-top-right absolute hover:cursor-pointer bg-gray-800 text-gray-400 right-0 w-40 rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  >
                    <div>
                      {sort.map((sort_item, index) => (
                        <Menu.Item key={index}>
                          <div
                            className={`px-4 py-2 text-sm ${
                              sortQuery === sort_item.field
                                ? "bg-gray-900 text-gray-300"
                                : ""
                            } hover:bg-gray-900 hover:text-gray-300 rounded-md`}
                            onClick={() => {
                              if (sortQuery === sort_item.field) {
                                setSortQuery("");
                                setSortLoading(true);
                              } else {
                                setSortQuery(sort_item.field);
                                setSortLoading(true);
                              }
                            }}
                          >
                            {sort_item.text}
                          </div>
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Disclosure>
    </div>
  );
}

FilterComponent.propTypes = {
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      field: PropTypes.string,
    })
  ),
  handleSort: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
          ]),
        })
      ),
    })
  ).isRequired,
  handleFilters: PropTypes.func.isRequired,
};
