import { Authentication } from "../lib/sarstuff_sso_offline";
import fetchRequest from "./fetchRequest";

const quickObjectToQueryString = (query) => {
  return (
    "?" +
    Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")
  );
};

const api = {
  myself: {
    profile: () =>
      fetchRequest
        .get(Authentication.getAPIServer() + `members/[me]`)
        .then((res) => res),
    updateStatus: (onCall) =>
      fetchRequest
        .patch(Authentication.getAPIServer() + `members/[me]/status`, {
          onCall,
        })
        .then((res) => res.data),
    vehicles: () =>
      fetchRequest
        .get(Authentication.getAPIServer() + `members/[me]/vehicles`)
        .then((res) => res),
    update: (data) =>
      fetchRequest
        .patch(Authentication.getAPIServer() + `members/[me]`, data)
        .then((res) => res),
    addCPDLog: (data) =>
      fetchRequest
        .post(Authentication.getAPIServer() + `members/[me]/cpd`, data)
        .then((res) => res),
    getCPDLogs: () =>
      fetchRequest
        .get(Authentication.getAPIServer() + `members/[me]/cpd`)
        .then((res) => res),
    getCPDLog: (lid) =>
      fetchRequest
        .get(Authentication.getAPIServer() + `members/[me]/cpd/${lid}`)
        .then((res) => res),
    updateCPDLog: (lid, data) =>
      fetchRequest
        .patch(Authentication.getAPIServer() + `members/[me]/cpd/${lid}`, data)
        .then((res) => res),
    deleteCPDLog: (lid) =>
      fetchRequest
        .delete(Authentication.getAPIServer() + `members/[me]/cpd/${lid}`)
        .then((res) => res),
  },
  vehicles: {
    show: (vid) =>
      fetchRequest
        .get(Authentication.getAPIServer() + `vehicle/${vid}`)
        .then((res) => res),
    update: (vid, data) =>
      fetchRequest
        .patch(Authentication.getAPIServer() + `vehicle/${vid}`, data)
        .then((res) => res),
    getPhoto: (id) =>
      fetchRequest
        .get(Authentication.getAPIServer() + `vehicle/${id}/photo`)
        .then((res) => res),
    archive: (vid, data) =>
      fetchRequest
        .patch(Authentication.getAPIServer() + `vehicle/${vid}/archive`, data)
        .then((res) => res),
    add: (data) =>
      fetchRequest
        .post(Authentication.getAPIServer() + `vehicle/`, data)
        .then((res) => res),
  },
  members: {
    all: () =>
      fetchRequest
        .get(Authentication.getAPIServer() + "members")
        .then((res) => res),
    show: (id) =>
      fetchRequest
        .get(Authentication.getAPIServer() + `members/${id}`)
        .then((res) => res),
    getPhoto: (id) =>
      fetchRequest
        .get(Authentication.getAPIServer() + `members/${id}/photo`)
        .then((res) => res),
  },
  training: {
    comps: {
      all: () =>
        fetchRequest
          .get(Authentication.getAPIServer() + `training/comps`)
          .then((res) => res),
      getMemberCompScore: (compId, userId) =>
        fetchRequest
          .get(
            Authentication.getAPIServer() +
              `training/comps/${compId}/members/${userId}/score`
          )
          .then((res) => res),
    },
    courses: {
      required: (id) =>
        fetchRequest
          .get(Authentication.getAPIServer() + `training/course/comps/${id}`)
          .then((res) => res),
      all: (query = {}) =>
        fetchRequest
          .get(
            Authentication.getAPIServer() +
              `/training/course` +
              quickObjectToQueryString(query)
          )
          .then((res) => res),
      show: (id) =>
        fetchRequest
          .get(Authentication.getAPIServer() + `/training/course/${id}`)
          .then((res) => res),
    },
  },
  events: {
    future: (type = "Training", limit = 30) =>
      fetchRequest
        .get(
          Authentication.getAPIServer() +
            `events/future?type=${type}&limit=${limit}`
        )
        .then((res) => res),
    show: (id) =>
      fetchRequest
        .get(Authentication.getAPIServer() + `events/${id}`)
        .then((res) => res),
    getBy: (date, type = "month") =>
      fetchRequest
        .get(
          Authentication.getAPIServer() +
            `events/period?date=${date}&type=${type}`
        )
        .then((res) => res),
    responses: {
      show: (id) =>
        fetchRequest
          .get(Authentication.getAPIServer() + `events/${id}/responses`)
          .then((res) => res),
      add: (id, data) =>
        fetchRequest
          .post(Authentication.getAPIServer() + `events/${id}/responses`, data)
          .then((res) => res),
      addRec: (id, data) =>
        fetchRequest
          .post(
            Authentication.getAPIServer() + `events/${id}/responses/recruit`,
            data
          )
          .then((res) => res),
    },
  },
  settings: {
    app: () =>
      fetchRequest
        .get(Authentication.getAPIServer() + `app/settings`)
        .then((res) => res),
    all: () =>
      fetchRequest
        .get(Authentication.getAPIServer() + `settings/system`)
        .then((res) => res),
    lists: () =>
      fetchRequest
        .get(Authentication.getAPIServer() + `settings/system/form`)
        .then((res) => res),
  },
  central: {
    getMe: () =>
      fetchRequest
        .get(Authentication.getAPIServer() + `app/unit_member/my_details`)
        .then((res) => res),
    updateStatus: (data) =>
      fetchRequest
        .patch(
          Authentication.getAPIServer() +
            `app/unit_member/my_details/oncall_status`,
          data
        )
        .then((res) => res),
  },
};
export default api;
