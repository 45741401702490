import Bridge from "./bridge";

class Authentication {
  constructor() {
    this.com = null;
    this.userData = null;
    this.access_token = null;
  }
  setComponent(com) {
    this.com = com;
  }
  getComponent() {
    return this.com;
  }
  logout() {
    this.getComponent().logout();
  }
  getAPIServer() {
    return Bridge.getAPIServer();
  }
  getSystemType(){
    return Bridge.getSystemType();
  }
  getSystemModules(){
    return Bridge.getSystemModules();
  }
  setUserData(data) {
    localStorage.setItem(
      Bridge.getConfig("localPrefix") + "__user-profile",
      JSON.stringify(data)
    );
    this.userData = data;
  }
  getUserData() {
    return this.userData;
  }
  getCachedUserData() {
    let data = localStorage.getItem(Bridge.getConfig("localPrefix") + "__user-profile");
    try {
      if (data !== undefined && data !== null) {
        return JSON.parse(data);
      }
    } catch (e) {
      // Do nothing
    }
    this.setAccessToken(null);
    this.setUserData({});
    return {};
  }
  setAccessToken(token) {
    this.access_token = token;
    if (token === null) {
      localStorage.removeItem(
        Bridge.getConfig("localPrefix") + "__access_token"
      );
    } else {
      localStorage.setItem(
        Bridge.getConfig("localPrefix") + "__access_token",
        token
      );
    }
  }
  getAccessToken() {
    if (this.access_token === null) {
      this.access_token = localStorage.getItem(
        Bridge.getConfig("localPrefix") + "__access_token"
      );
    }
    return this.access_token;
  }
  hasScope(scope) {
    if (this.userData.scopes !== undefined) {
      return this.userData.scopes.indexOf(scope) !== -1;
    }

    return false;
  }
}

export default new Authentication();
