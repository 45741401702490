import React, { useState, useEffect, lazy } from "react";
import api from "../api";
import TeamFilter from "../components/FilterComponent";
import Settings_Receiver from "../context/settings_context/Settings_Receiver";
import ChainedContext from "../context/chainedContext";

const Header = lazy(() => import("../components/Header"));
const MemberLine = lazy(() => import("../components/MemberLine"));

function TeamList({ history, settings }) {
  const [filteredList, setFilteredList] = useState([]);
  const [params, setParams] = useState({});

  const [loading, setLoading] = useState(false);
  const [mountLoading, setMountLoading] = useState(true);

  const fetchData = async () => {
    return await api.members.all();
  };

  useEffect(() => {
    if (mountLoading) {
      fetchData().then((res) => {
        setFilteredList(res);
        setMountLoading(false);
      });
    }

    // The default handling assumes that you will be passing a string, you must define exceptions using the field name as show below for the "roles" filter.
    if (loading) {
      fetchData().then((res) => {
        let modifiedList = res;

        if (params.search && params.search.length > 2) {
          // console.log(modifiedList);
          modifiedList = modifiedList.filter(
            (item) =>
              item.name &&
              item.name.toLowerCase().includes(params.search.toLowerCase())
          );
        }

        if (params.sort) {
          modifiedList = modifiedList.sort((a, b) =>
            params.sort === "firstName"
              ? a.name
                  .split(" ")
                  .map((item) => item.trim())
                  .filter((item) => (item ? true : false))[0] <
                b.name
                  .split(" ")
                  .map((item) => item.trim())
                  .filter((item) => (item ? true : false))[0]
                ? -1
                : a.name
                    .split(" ")
                    .map((item) => item.trim())
                    .filter((item) => (item ? true : false))[0] >
                  b.name
                    .split(" ")
                    .map((item) => item.trim())
                    .filter((item) => (item ? true : false))[0]
                ? 1
                : 0
              : params.sort === "lastName"
              ? a.name
                  .split(" ")
                  .map((item) => item.trim())
                  .filter((item) => (item ? true : false))[
                  a.name
                    .split(" ")
                    .map((item) => item.trim())
                    .filter((item) => (item ? true : false)).length - 1
                ] <
                b.name
                  .split(" ")
                  .map((item) => item.trim())
                  .filter((item) => (item ? true : false))[
                  a.name
                    .split(" ")
                    .map((item) => item.trim())
                    .filter((item) => (item ? true : false)).length - 1
                ]
                ? -1
                : a.name
                    .split(" ")
                    .map((item) => item.trim())
                    .filter((item) => (item ? true : false))[
                    a.name
                      .split(" ")
                      .map((item) => item.trim())
                      .filter((item) => (item ? true : false)).length - 1
                  ] >
                  b.name
                    .split(" ")
                    .map((item) => item.trim())
                    .filter((item) => (item ? true : false))[
                    a.name
                      .split(" ")
                      .map((item) => item.trim())
                      .filter((item) => (item ? true : false)).length - 1
                  ]
                ? 1
                : 0
              : a[params.sort] < b[params.sort]
              ? -1
              : a[params.sort] > b[params.sort]
              ? 1
              : 0
          );
        }

        if (params.filters && Object.keys(params.filters).length > 0) {
          modifiedList = modifiedList.filter((item) => {
            let failed = false;

            Object.keys(params.filters).forEach((filter_item) => {
              if (filter_item === "roles") {
                if (
                  item[filter_item] === undefined ||
                  (item[filter_item] !== undefined &&
                    item[filter_item].find(
                      (group) => group === params.filters[filter_item]
                    )) === undefined
                ) {
                  failed = true;
                }
              } else {
                if (
                  item[filter_item] === undefined ||
                  (item[filter_item] !== undefined &&
                    item[filter_item] !== params.filters[filter_item])
                ) {
                  failed = true;
                }
              }
            });

            return failed ? false : true;
          });
        }

        setFilteredList(modifiedList);
        setLoading(false);
      });
    }
  }, [mountLoading, loading, params]);

  return (
    <div className="w-full">
      <Header title={"Team List"} />
      <div className="">
        <div className="p-4">
          <input
            name="search"
            autoComplete="off"
            placeholder={`Search`}
            onChange={(e) => {
              setParams({ ...params, [e.target.name]: e.target.value });
              setLoading(true);
            }}
            className="p-2 bg-gray-900 rounded-xl border border-gray-700 w-full focus:none"
          />
        </div>
        <TeamFilter
          sort={[
            { text: "First Name", field: "firstName" },
            { text: "Last Name", field: "lastName" },
            { text: "Team ID", field: "teamId" },
          ]}
          filters={[
            {
              title: "Status",
              field: "onCall",
              options: [
                { text: "On Call", value: true },
                { text: "Off Call", value: false },
              ],
            },
            {
              title: "Organisation Role",
              field: "roles",
              options:
                settings && settings.appOrgRolesList
                  ? settings.appOrgRolesList.map((item) => ({
                      text: item,
                      value: item,
                    }))
                  : [],
            },
          ]}
          handleSort={(data) => {
            setParams({ ...params, sort: data });
            setLoading(true);
          }}
          handleFilters={(data) => {
            setParams({ ...params, filters: data });
            setLoading(true);
          }}
        />
        <table className="min-w-full divide-y divide-gray-700 border border-gray-700">
          <tbody className="divide-y divide-gray-700">
            {filteredList.map((l) => {
              return <MemberLine data={l} history={history} key={l._id} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ChainedContext(TeamList, [
  [
    Settings_Receiver,
    (settings) => {
      return { settings };
    },
  ],
]);
