import React, { useState, useEffect, lazy } from "react";

import dayjs from "dayjs";
import api from "../api";
// import "react-calendar/dist/Calendar.css";
const EventLine = lazy(() => import("../components/EventLine"));
const Header = lazy(() => import("../components/Header"));
const Calendar = lazy(() => import("../components/Calendar"));

export default function Calender({ history }) {
  const [loading, setLoading] = useState(true);
  // const [events, setEvents] = useState([]);
  const [calEvents, setCalEvents] = useState([]);
  const [dayEvents, setDayEvents] = useState([]);
  // const [date, setDate] = useState("");
  // const [value, onChange] = useState(new Date());
  useEffect(() => {
    setLoading(false);
    // setDate(dayjs().format("YYYY-MM-DD"));
    getMonthEvents(dayjs().format("YYYY-MM-DD"));
  }, []);

  const getMonthEvents = (date) => {
    api.events.getBy(dayjs(date).format("YYYY-MM-DD"), "month").then((res) => {
      console.log(res);
      setCalEvents(
        res.sort((a, b) => {
          if (a.start < b.start) {
            return -1;
          } else if (a.start > b.start) {
            return 1;
          } else {
            return 0;
          }
        })
      );
      setDayEvents([]);
    });
  };
  const getDayEvents = (date) => {
    // console.log("fireing day........", date);

    api.events.getBy(dayjs(date).format("YYYY-MM-DD"), "day").then((res) => {
      setDayEvents(res);
      // setEvents([]);
    });
  };
  // function tileContent({ date, view }) {
  //   // Add class to tiles in month view only
  //   if (view === "month") {
  //     // Check if a date React-Calendar wants to check is on the list of dates to add class to
  //     if (datesToAddContentTo.find((dDate) => isSameDay(dDate, date))) {
  //       return "My content";
  //     }
  //   }
  // }
  // console.log(events);
  return (
    <div className="w-full  text-center justify-center flex flex-col">
      {loading && "Loading..."}

      <Header title={"Calendar"} />

      <div className="w-full p-2 mx-auto">
        <Calendar eventsList={calEvents} queryMonth={getMonthEvents} queryDay={getDayEvents} />
      </div>
      <div className="w-full p-2">
        <div className="text-2xl text-center py-4 tracking-widest text-slate-600 ">Events</div>
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="divide-y divide-gray-700 bg-gray-900">
            <tr className="border-t border-gray-700 text-lg">
              <th className="p-2 uppercase text-slate-500 ">Start</th>
              <th className="p-2 uppercase text-slate-500 ">Event</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700 cursor-pointer">
            {dayEvents.length > 0
              ? dayEvents.map((l) => {
                  return <EventLine data={l} key={l._id} history={history} />;
                })
              : calEvents.map((l) => {
                  return <EventLine data={l} key={l._id} history={history} />;
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
