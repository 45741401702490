import React from "react";

export default function Header({
  title,
  sub,
  style,
  titleColour = "text-white",
  subColour = "text-primary",
}) {
  return (
    <div className={`bg-gray-900 border-b border-gray-700 ${style}`}>
      <div
        className={`text-xl text-center py-4 tracking-widest ${titleColour} flex flex-col uppercase`}
      >
        {title}
        {sub && <span className={`text-sm italic ${subColour}`}>{sub}</span>}
      </div>
    </div>
  );
}
