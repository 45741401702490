import React, { useState, useEffect, lazy } from "react";
import dayjs from "dayjs";
import api from "../api";
import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline";
import logo from "../assets/teamsite_logo.png";
import toast from "react-hot-toast";

const Loader = lazy(() => import("../components/Loader"));
const Header = lazy(() => import("../components/Header"));
const Touchable = lazy(() => import("../components/Touchable"));
const EventLine = lazy(() => import("../components/EventLine"));

export default function HomePage({ history }) {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [user, setUser] = useState({
    onCall: true,
  });

  useEffect(() => {
    getData();
  }, []);

  const changeStatus = () => {
    // setUser({ onCall: !user.onCall });
    api.myself.updateStatus(!user.onCall).then(() => {
      // console.log(!user.onCall);
      let message = `Status changed to ${!user.onCall ? "On-Call" : "Off-Call"}`;
      toast.success(message);
      getData();
    });
  };
  const getData = () => {
    Promise.all([api.myself.profile(), api.events.future()])
      .then((res) => {
        setUser(res[0]);
        setEvents(res[1]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
    // api.myself.profile().then((res) => {
    //   setUser(res);
    //   setLoading(false);
    // });
    // api.events.future().then((res) => {
    //   setEvents(res);
    // });
  };
  console.log(events);
  return (
    <div className="w-full ">
      {/* {loading && "Loading..."} */}
      <Header title={<img src={logo} alt={"Logo"} className="h-20 object-scale-down	 " />} />

      <div className="p-2 flex gap-4">
        <div className="flex flex-col justify-between p-2 w-full rounded-lg border-2 border-gray-700 hover:bg-gray-900 text-center">
          <div className="text-5xl">{dayjs().format("DD")}</div>
          <div className="tracking-widest text-gray-400 text-xl py-1 ">{dayjs().format("MMMM YYYY")}</div>
        </div>
        {user.operational ? (
          <Touchable
            loading={loading}
            title={user.onCall ? "On-Call" : "Off-Call"}
            icon={user.onCall ? <ThumbUpIcon className="h-12 text-green-200" /> : <ThumbDownIcon className="h-12 text-red-400" />}
            style={user.onCall ? "bg-green-800 border-green-500 hover:bg-green-900 text-green-200" : "bg-red-800 text-red-200 border-red-500 hover:bg-red-900"}
            action={() => changeStatus(user.onCall ? { status: false } : { status: true })}
          />
        ) : (
          <Touchable
            loading={loading}
            title={"Non-Op"}
            icon={<ThumbDownIcon className="h-12 text-red-400" />}
            style={"bg-red-800 text-red-200 border-red-500 hover:bg-red-900"}
            action={() => {}}
          />
        )}
        {/* <Touchable
          loading={loading}
          title={user.onCall ? "On-Call" : "Off-Call"}
          icon={
            user.onCall ? (
              <ThumbUpIcon className="h-12 text-green-200" />
            ) : (
              <ThumbDownIcon className="h-12 text-red-400" />
            )
          }
          style={
            user.onCall
              ? "bg-green-800 border-green-500 hover:bg-green-900 text-green-200"
              : "bg-red-800 text-red-200 border-red-500 hover:bg-red-900"
          }
          action={() =>
            changeStatus(user.onCall ? { status: false } : { status: true })
          }
        /> */}
      </div>
      <div className="text-center p-2 text-2xl tracking-widest text-slate-500">Upcoming Events</div>
      {loading && <Loader />}
      <table className="min-w-full divide-y divide-gray-700">
        <thead className="divide-y divide-gray-700 bg-gray-900">
          <tr className="border-t border-gray-700 text-lg">
            <th className="p-2 uppercase text-slate-500 ">Start</th>
            <th className="p-2 uppercase text-slate-500 ">Event</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-700 cursor-pointer">
          {events.map((l) => {
            return <EventLine data={l} key={l._id} history={history} />;
          })}
        </tbody>
      </table>
    </div>
  );
}
