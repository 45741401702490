import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

export default function Select2({
  field,
  label,
  error,
  options,
  onChange,
  value = "",
  disabled,
}) {
  const [selected, setSelected] = useState({ key: -1, text: "--" });

  //   console.log("88888", options);
  useEffect(() => {
    if (value !== "" && options.length > 0) {
      let iValue = options.filter((f) => {
        return f.value === value;
      });
      setSelected(iValue[0]);
    }
  }, [value, options]);

  const onLocalChange = (e) => {
    let data = {
      target: {
        name: field,
        value: e.value,
      },
    };
    onChange(data);
  };
  // console.log("e", error);
  return (
    <div className="w-full">
      <label
        htmlFor={field}
        className={`block text-sm font-medium  ${
          error ? "text-red-400" : "text-blue-400"
        }`}
      >
        {label}
      </label>
      <Listbox value={selected} onChange={onLocalChange} disabled={disabled}>
        <div className="relative ">
          <Listbox.Button
            className={` relative w-full py-2 pl-3 pr-10 text-left bg-gray-900 rounded-lg shadow-md cursor-default  focus-visible:ring focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2  focus-visible:ring-opacity-75 sm:text-sm border  ${
              error ? "border-red-400" : "border-gray-600"
            }`}
          >
            <span className="block truncate">{selected.text}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="z-50 absolute w-full py-1 mt-1 overflow-auto text-base bg-gray-900 rounded-md shadow-lg max-h-60 focus:outline-none sm:text-sm border-2 border-gray-500">
              {options.map((o, i) => (
                <Listbox.Option
                  key={i}
                  className={({ active }) =>
                    `cursor-default select-none relative py-2 pl-10 pr-4 ${
                      active ? "text-white bg-gray-800" : "text-gray-100"
                    }`
                  }
                  value={o}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {o.text}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                          <CheckIcon
                            className="w-5 h-5 text-primary"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
