/**
 * This class exits solely to store config data in memory so that other classes can access it in a singular fashion
 */
class Bridge {
  constructor() {
    this.config = null;
    this.api_server = "";
    this.systemType = "";
    this.systemModules = {};
  }

  setSystemType(type){
      localStorage.setItem("TEMP__SYSTEM_TYPE", type);
      this.systemType = type;
  }
  getSystemType(){
      let local = localStorage.getItem("TEMP__SYSTEM_TYPE");
      if (this.systemType === "") {
          this.systemType = local;
      }
      return this.systemType;
  }
  setSystemModules(type){
      localStorage.setItem("TEMP__SYSTEM_MODULES", JSON.stringify(type));
      this.systemModules = type;
  }
  getSystemModules(){
      let local = localStorage.getItem("TEMP__SYSTEM_MODULES");
      if (this.systemModules === "") {
          this.systemModules = JSON.parse(local);
      }
      return this.systemModules;
  }

  setAPIServer(api) {
    const isLocalDevSet = localStorage.getItem("TEMP__LOCALDEV");
    if(isLocalDevSet === "true"){
        api = "http://localhost:3000/api/";
    }
    localStorage.setItem("TEMP__API_SERVER", api);
    this.api_server = api;
  }
  getAPIServer() {
    let local = localStorage.getItem("TEMP__API_SERVER");
    if (this.api_server === "") {
      this.api_server = local;
    }
    return this.api_server;
  }
  setConfig(config) {
    this.config = config;
  }
  getConfig(item) {
    return this.config[item];
  }
  getRAWConfig() {
    return this.config;
  }
}
export default new Bridge();
