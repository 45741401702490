import React, { useState, useEffect, lazy } from "react";
import { useParams } from "react-router-dom";
import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline";

import api from "../api";
import {Authentication} from "../lib/sarstuff_sso_offline";

const DataField = lazy(() => import("../components/DataField"));
const Header = lazy(() => import("../components/Header"));

export default function MemberDetails() {
  const [member, setMember] = useState({});
  const [memberPhotoLink, setMemberPhotoLink] = useState("no_photo");
  const params = useParams("team_id");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.members.show(params.team_id).then((res) => {
      setMember(res);
    });
    api.members.getPhoto(params.team_id).then(res => {
      setMemberPhotoLink(Authentication.getAPIServer() + ".." + res.profileImage);
    });
  };

  return (
    <div className="w-full">
      <Header title={member.name} />

      <DataField
        label="Phone"
        data={member.privacy && member.privacy.hidePhone ? "--" : member.phone}
      />
      <DataField
        label="Email"
        data={member.privacy && member.privacy.hideEmail ? "--" : member.email}
      />

      <DataField
        label="Town"
        data={
          member.privacy && member.privacy.hideAddress
            ? "--"
            : member.geoJSON && member.geoJSON.properties
            ? member.geoJSON.properties.town
            : "--"
        }
      />
      <div className="flex text-center">
        <DataField
          label="Operational"
          data={
            member.operational ? (
              <ThumbUpIcon className="h-12 text-green-200 mx-auto" />
            ) : (
              <ThumbDownIcon className="h-12 text-red-400 mx-auto" />
            )
          }
        />
        <DataField
          label="On-Call"
          data={
            member.onCall ? (
              <ThumbUpIcon className="h-12 text-green-200 mx-auto" />
            ) : (
              <ThumbDownIcon className="h-12 text-red-400 mx-auto" />
            )
          }
        />
      </div>
      <div className="flex">
        <DataField label="Team ID" data={member.teamId} />
        <DataField label="Force #" data={member.forceId} />
      </div>
      {memberPhotoLink !== "no_photo" && (
        <div className="sm:m-2">
          <img
            alt={"photo"}
            src={memberPhotoLink}
            className="mx-auto border-4 border-white  shadow-xl"
          />
        </div>
      )}
      <div className={`pb-2  flex-1 border-t border-b border-gray-700	`}>
        <div
          className={`text-xl text-primary pb-2 pt-2 tracking-widestbg-gray-900 bg-opacity-60 text-mono border-b border-gray-800 text-center`}
        >
          Roles
        </div>
        <div className="m-2 flex flex-wrap gap-2">
          {member.roles && member.roles.length > 0 ? (
            member.roles.map((r, i) => {
              return (
                <div
                  key={i}
                  className="p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
                >
                  {r}
                </div>
              );
            })
          ) : (
            <div className={"w-full text-center text-gray-500 p-2"}>
              No roles
            </div>
          )}
        </div>
      </div>

      {/* <div className="flex w-full ">
        <DataField
          label="Start Time"
          data={dayjs(event.start).format("DD-MM-YY HH:mm")}
        />
        <DataField
          label="End Time"
          data={dayjs(event.end).format("DD-MM-YY HH:mm")}
        />
      </div> */}
    </div>
  );
}
