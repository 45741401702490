import React from "react";
import {
  CalendarIcon,
  HomeIcon,
  MenuIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";

function Footer({ setOpen }) {
  return (
    <div className="w-full border-t border-gray-700  h-24 pb-12 flex justify-around items-end fixed bottom-0 bg-gray-900">
      <NavLink to={"/you"}>
        <UserIcon className="h-8 text-gray-300 " />
      </NavLink>
      <NavLink to={"/events"}>
        <CalendarIcon className="h-8 text-gray-300 " />
      </NavLink>
      <NavLink
        to={"/"}
        className=" text-blue-200 bg-gray-900 rounded-full border-t border-gray-700  p-4 shadow-xl"
      >
        <HomeIcon className="h-12 text-blue-200 " />
      </NavLink>
      <NavLink to={"/team"}>
        <UsersIcon className="h-8 text-gray-300 " />
      </NavLink>
      <button onClick={() => setOpen(true)} aria-label="Open Menu">
        <MenuIcon className="h-8 text-gray-300" />
      </button>
    </div>
  );
}
export default withRouter(Footer);
