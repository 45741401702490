import React, { Suspense, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import AppConfig from "./_appConfig";
import HomePage from "./pages/home";
import Footer from "./components/Footer";
import TeamList from "./pages/teamList";
import Calender from "./pages/calender";
import YourDetails from "./pages/yourDetails";
import SideBar from "./components/SideBar";
import { AuthenticationWrapper } from "./lib/sarstuff_sso_offline";
import loginScreen from "./components/loginScreen";
import Axios from "axios";
import UserProvider from "./context/user_context/User_Provider";
import SettingsProvider from "./context/settings_context/Settings_Provider";
import "react-datepicker/dist/react-datepicker.css";
import api from "./api";
import Event from "./pages/event";
import MemberDetails from "./pages/memberDetails";
import EditYourDetails from "./pages/editYourDetails";
import VehicleDetails from "./pages/vehicleDetails";
import editVehicle from "./pages/editVehicle";
import addVehicle from "./pages/addVehicle";
import privacySettings from "./pages/privacySettings";
import CPDPage from "./pages/CPDPage";
import course from "./pages/course";

const MainUserProvider = ({ children }) => {
  return <UserProvider api={api}>{children}</UserProvider>;
};
const TheSettingsProvider = ({ children }) => {
  return <SettingsProvider api={api}>{children}</SettingsProvider>;
};

function App() {
  const [open, setOpen] = useState(false);
  return (
    <Suspense fallback={<div>Loading</div>}>
      <AuthenticationWrapper
        appNameShort={"Team Site"}
        appName={"Team Site"}
        loginScreen={loginScreen}
        getAPI={async (profile) => {
          try {
            let systems = await Axios.get("https://central.sarstuff.co.uk/app_config/teamsite/organisation/" + profile.organisation._id).then((res) => res.data);
            if (systems[0] !== undefined) {
              return {
                authorise: systems[0].endpoint + "/oauth/app_authorise",
                token: systems[0].endpoint + "/oauth/token",
                endpoint: systems[0].endpoint + "/api/",
              };
            }
            return false;
          } catch (e) {}
          return null;
        }}
        config={{
          isAppMode: true,
          clientId: AppConfig.auth.clientId,
          localPrefix: AppConfig.app_storage_prefix,
        }}
      >
        <MainUserProvider>
          <TheSettingsProvider>
            <Toaster />
            <SideBar show={open} onClose={() => setOpen(false)} />
            <Switch>
              <Route exact path={`/`} component={HomePage} />
              <Route exact path={`/vehicle/:vehicle_id/edit`} component={editVehicle} />
              <Route exact path={`/vehicle/add`} component={addVehicle} />
              <Route exact path={`/team/:team_id`} component={MemberDetails} />
              <Route exact path={`/team`} component={TeamList} />
              <Route exact path={`/vehicle/:vehicle_id`} component={VehicleDetails} />
              <Route exact path={`/course/:event_id`} component={course} />
              <Route exact path={`/events/:event_id`} component={Event} />
              <Route exact path={`/events`} component={Calender} />
              <Route exact path={`/you/edit`} component={EditYourDetails} />
              <Route exact path={`/you/cpd`} component={CPDPage} />
              <Route exact path={`/you/privacy`} component={privacySettings} />
              <Route exact path={`/you`} component={YourDetails} />
            </Switch>
            <div className="mb-2 pb-32 h-32 justify-center flex">{""}</div>
            <Footer setOpen={setOpen} />
          </TheSettingsProvider>
        </MainUserProvider>
      </AuthenticationWrapper>
    </Suspense>
  );
}

export default App;
