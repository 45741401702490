import { Authentication } from "../lib/sarstuff_sso_offline";

class FetchRequest {
  getData = async (url = "") => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + Authentication.getAccessToken(),
      },
    });
    return response.json();
  };
  getTextData = async (url = "") => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + Authentication.getAccessToken(),
      },
    });
    return response.text();
  };
  deleteData = async (url = "") => {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + Authentication.getAccessToken(),
      },
    });
    return response.text();
  };
  postData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authentication.getAccessToken(),
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };
  postFormData = async (url = "", data) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + Authentication.getAccessToken(),
      },
      body: data,
    });
    return response.json();
  };
  patchData = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Authentication.getAccessToken(),
      },
      body: JSON.stringify(data),
    });
    return response.json();
  };
  async get(url) {
    const res = await this.getData(url);
    if (res.statusCode === 404) {
      return [];
    } else {
      return res;
    }
  }
  async getText(url) {
    const res = await this.getTextData(url);
    if (res.statusCode === 404) {
      return [];
    } else {
      return res;
    }
  }
  async post(url, data) {
    const res = await this.postData(url, data);
    return res;
  }
  async delete(url) {
    const res = await this.deleteData(url);
    return res;
  }
  async patch(url, data) {
    const res = await this.patchData(url, data);
    return res;
  }
}

export default new FetchRequest();
