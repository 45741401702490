import React from "react";
import User from "./User_Context";
import { withRouter } from "react-router-dom";

class User_Receiver extends React.Component {
  state = {
    user: {},
  };
  componentDidMount() {
    this._refresh();
  }
  _refresh = () => {
    this.props.api.myself.profile().then((res) => {
      this.setState({
        user: res,
      });
    });
  };
  render() {
    return (
      <User.Provider
        value={{
          _refresh: this._refresh,
          ...this.state.user,
        }}
      >
        {this.props.children}
      </User.Provider>
    );
  }
}
export default withRouter(User_Receiver);
