import React, { Component } from "react";
import query_string from "query-string";
import Bridge from "../bridge";
import Authentication from "../Authentication";
import API from "../actions/api";
import TeamSiteLogo from "../../../assets/teamsite_logo.png";
/**
 * Tailwind CSS Authentication Wrapper
 * 2022-03-31
 */

const defaultConfig = {
  loginServer: "https://central.sarstuff.co.uk",
  applicationRoot: window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/",
};

class AuthenticationWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      reload: false,
      processing: false,
      loading: true,
      loggedIn: false,
      error: false,
      user: {},
      access_token: "",
    };
    Bridge.setConfig({
      ...defaultConfig,
      ...props.config,
    });
    Authentication.setComponent(this);
  }
  componentDidMount() {
    this.initiateLoginProcess();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    Bridge.setConfig({
      ...defaultConfig,
      ...this.props.config,
    }); // Just in case any of the config options were changed after we have already mounted the config
  }

  initiateLoginProcess() {
    let urlParams = query_string.parse(window.location.search);
    if (urlParams.code !== undefined) {
      this.setState(
        {
          processing: true,
        },
        () => {
          this.login(urlParams.code, urlParams.organisation);
        }
      );
      return;
    }
    this.checkLogin();
  }
  checkLogin() {
    let access_token = Authentication.getAccessToken();
    if (access_token !== undefined && access_token !== null) {
      if (navigator.onLine) {
        // User is Online
        let currentProfile = Authentication.getCachedUserData(); // Get current profile to retrieve the org ID
        if (currentProfile.email !== undefined) {
          this.props
            .getAPI(currentProfile)
            .then((api) => {
              if (api === null) {
                // There was an error
                this.setState({
                  loading: false,
                  loggedIn: false,
                  user: {},
                  access_token: "",
                  error: "service_error",
                });
                return;
              }
              if (api === false) {
                // No SarSYS system for this login
                this.setState({
                  loading: false,
                  loggedIn: false,
                  user: {},
                  access_token: "",
                  error: "no_system_error",
                });
                return;
              }

              Bridge.setSystemType(api.system);
              Bridge.setSystemModules(api.modules);
              Bridge.setAPIServer(api.endpoint);

              API.auth
                .user_details(access_token)
                .then((res) => {
                  Authentication.setUserData(res);
                  Authentication.setAccessToken(access_token);
                  try {
                    this.setState({
                      user: res,
                      loading: false,
                      loggedIn: true,
                      access_token: access_token,
                    });
                  } catch (e) {
                    console.error("[AuthenticationWrapper] A Child Component triggered an Exception: ", e);
                  }
                })
                .catch((err) => {
                  let error = "service_error";
                  if (err.response !== undefined) {
                    Authentication.setAccessToken(null);
                    error = "invalid_code";
                  }
                  this.setState({
                    loading: false,
                    loggedIn: false,
                    user: {},
                    access_token: "",
                    error,
                  });
                });
            })
            .catch((err) => {});
          return;
        }
      } else {
        // User is Offline
        console.log("[AuthenticationWrapper] ### Loading from Cache the login session, user is offline. ###");
        let currentProfile = Authentication.getCachedUserData();
        if (currentProfile.email !== undefined) {
          this.setState(
            {
              loading: false,
              loggedIn: true,
              user: currentProfile,
              access_token: access_token,
            },
            () => {
              console.log("user cache state", this.state.user);
            }
          );
          return;
        }
      }
    }
    // No existing tokens in store so lets start the login process
    Authentication.setAccessToken(null);
    Authentication.setUserData({});
    if (navigator.onLine) {
      // User is Online
      this.setState({
        loading: false,
        loggedIn: false,
        user: {},
        access_token: "",
        redirect: true,
      });
    } else {
      // User is Offline
      this.setState({
        loading: false,
        loggedIn: false,
        user: {},
        access_token: "",
        error: "offline",
      });
    }
  }
  login(code, organisation) {
    this.props.getAPI({ organisation: { _id: organisation } }).then((api) => {
      if (api === null) {
        // There was an error
        this.setState({
          processing: false,
          loading: false,
          loggedIn: false,
          user: {},
          access_token: "",
          error: "service_error",
        });
        return;
      }
      if (api === false) {
        // No SarSYS system for this login
        this.setState({
          processing: false,
          loading: false,
          loggedIn: false,
          user: {},
          access_token: "",
          error: "no_system_error",
        });
        return;
      }

      Bridge.setSystemType(api.system);
      Bridge.setSystemModules(api.modules);
      Bridge.setAPIServer(api.endpoint);
      API.auth
        .exchange_code(code)
        .then((res) => {
          API.auth.user_details(res.access_token).then((details) => {
            Authentication.setUserData(details);
            Authentication.setAccessToken(res.access_token);
            this.setState({
              user: details,
              loading: false,
              processing: false,
              loggedIn: true,
              reload: true,
              access_token: res.access_token,
            });
          });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              if (err.response.data.error === "invalid_code") {
                this.setState({
                  loading: false,
                  loggedIn: false,
                  processing: false,
                  user: {},
                  access_token: "",
                  error: "invalid_code",
                });
                return;
              } else if (err.response.data.error === "access_denied") {
                this.setState({
                  loading: false,
                  loggedIn: false,
                  processing: false,
                  user: {},
                  access_token: "",
                  error: "access_denied",
                });
                return;
              }
            }
            this.setState({
              loading: false,
              loggedIn: false,
              processing: false,
              user: {},
              access_token: "",
              error: "service_error",
            });
            return;
          }
          this.setState({
            loading: false,
            loggedIn: false,
            processing: false,
            user: {},
            access_token: "",
            error: "offline",
          });
        });
    });
  }
  logout() {
    API.auth
      .logout()
      .then(() => {})
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        Authentication.setUserData({});
        Authentication.setAccessToken(null);
        this.setState({
          loading: false,
          loggedIn: false,
          user: {},
          access_token: "",
          redirect: true,
        });
      });
  }
  getLoginURL() {
    let config = Bridge.getRAWConfig();
    return config.loginServer + "/oauth/authorize?client_id=" + config.clientId + "&redirect_uri=" + config.applicationRoot + "&response_type=code&scope=profile";
  }
  _triggerLoginButton() {
    window.location = this.getLoginURL();
  }

  render() {
    let config = Bridge.getRAWConfig();
    if (this.state.reload) {
      window.location = config.applicationRoot;
      return <div className={"p-2 text-lg text-center text-white"}>Reloading Application...</div>;
    }
    if (
      (config.clientId === undefined || config.localPrefix === undefined) && //||
      //config.applicationServer === undefined) &&
      process.env.NODE_ENV !== "production"
    ) {
      return (
        <div className="w-full" textAlign={"center"}>
          <div className="bg-red-400 text-white">
            <div className="text-xl p-2">Error</div>
            <br />
            There is a misconfiguration with the AuthenticationWrapper. Please ensure: 'clientId', 'localPrefix' & 'applicationServer' are set and passed via the config prop.
          </div>
        </div>
      );
    }
    if (this.state.error) {
      if (this.state.error === "access_denied") {
        return (
          <div className="p-4 text-center">
            <div className="p-2 bg-red-600 text-white">
              <div className="text-xl">Access Denied</div>
              <br />
              Your SarStuff Central account and/or organisation does not have the correct access rights to login into this application therefore access has been denied.
              <br />
              <br />
              If you believe that you should have access to this application then please contact your Organisation Admins to obtain access. Otherwise please send an email to:{" "}
              <strong>support@sarstuff.co.uk</strong>
            </div>
            <br />
            <button onClick={() => (window.location = "/")} className="bg-green-600 rounded-md p-2 text-white">
              Retry Login
            </button>
            <br />
            <br />
            <div className={"p-2 bg-blue-600"}>
              <div className="text-xl">Things To Check</div>
              <br />
              1. <strong>Are you logging into the correct application?</strong> A few of our systems have unique website addresses that are organisation specific, check the website
              address above is correct for your organisation.
              <br />
              2. <strong>Insufficient security groups assigned to your account?</strong> You may not have the correct security groups assigned to your SarStuff Central account to
              grant you access to this application.
              <br />
              3. <strong>Application not enabled for organisation?</strong> Your organisation might not have access to this application, please contact: sales@sarstuff.co.uk if you
              think this is in error.
            </div>
          </div>
        );
      } else if (this.state.error === "invalid_code") {
        return (
          <div className={"p-4 text-center max-w-3xl mx-auto"}>
            <img alt="TeamSite Logo" src={TeamSiteLogo} className="w-1/2 mx-auto border-0 pt-4" />
            <div className="p-4 bg-red-900 rounded ">
              <div className="text-xl">Session Expired</div>
              <p className={"py-3"}>It appears that your login session has expired. You must re-authenticate before continuing.</p>
              <button onClick={() => (window.location = "/")} className="bg-green-600 rounded-md p-2 text-white">
                Login
              </button>
            </div>
          </div>
        );
      } else if (this.state.error === "offline") {
        return (
          <div className={"p-4 text-center"}>
            <div className="p-2 bg-red-600">
              <div className="text-xl">Offline</div>
              <p className={"py-3"}>
                It appears that you do not have an active internet connection at this time. Please connect to the internet in order to start a new login session.
              </p>
              <button onClick={() => (window.location = "/")} className="bg-green-600 rounded-md p-2 text-white">
                Re-Check Connection
              </button>
            </div>
          </div>
        );
      } else if (this.state.error === "no_system_error") {
        return (
          <div className={"p-4 text-center"}>
            <div className="p-2 bg-blue-600">
              <div className="text-xl">No {this.props.appNameShort} Account</div>
              <p className={"py-3"}>
                It appears that your organisation does not have {this.props.appName}. If you think this is in error then contact SarStuff Support at:{" "}
                <strong>support@sarstuff.co.uk</strong>.
              </p>
              {this.props.noAccountBanner !== undefined && <p className={"pb-3"}>{this.props.noAccountBanner}</p>}
              <button onClick={() => (window.location = "/")} className="bg-green-600 rounded-md p-2 text-white">
                Try Again
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className={"p-4 text-center"}>
          <div className="p-2 bg-red-600">
            <div className="text-xl">Service Error</div>
            <p className={"py-3"}>
              We were unable to log you into the application as this time. This could be due to a temporary server issue or a problem with your internet connection. <br />
              <br />
              Please try again in a few moments. If the issue continues contact SarStuff support at: <strong>support@sarstuff.co.uk</strong>
            </p>
            <button onClick={() => (window.location = "/")} className="bg-green-600 rounded-md p-2 text-white">
              Try Again
            </button>
          </div>
        </div>
      );
    }
    if (this.state.processing) {
      return <div className={"p-2 text-lg text-center text-white"}>Processing Login...</div>;
    }
    if (this.state.loading) {
      return <div className={"p-2 text-lg text-center text-white"}>Loading...</div>;
    }
    if (!this.state.loggedIn) {
      if (this.state.redirect) {
        if (this.props.loginScreen !== undefined) {
          const CustomScreen = this.props.loginScreen;
          return <CustomScreen login={() => this._triggerLoginButton()} />;
        } else {
          window.location = this.getLoginURL();
          return <div className={"p-2 text-lg text-center text-white"}>Redirecting to SarStuff Central...</div>;
        }
      }
      return <div className={"p-2 text-lg text-center text-white"}>Processing Login...</div>;
    } else {
      return this.props.children;
    }
  }
}

export default AuthenticationWrapper;
