import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, XIcon } from "@heroicons/react/outline";
import { FiLogOut } from "react-icons/fi";
import { FaCar } from "react-icons/fa";
import { BiHide } from "react-icons/bi";
import { IoIosSchool } from "react-icons/io";
import ChainedContext from "../context/chainedContext";
import Settings_Receiver from "../context/settings_context/Settings_Receiver";

// import User_Receiver from "../../../contexts/user_context/User_Receiver";
// import Location_Receiver from "../../../contexts/location_context/Location_Receiver";
import { Authentication } from "../lib/sarstuff_sso_offline";
import _buildNumber from "../_buildNumber";
// import api from "../api";
// import Loader from "./Loader";

import { NavLink } from "react-router-dom";

function SideBar({ show, onClose, settings }) {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    if (show === true) {
      settings._refresh();
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);
  useEffect(() => {
    let userData = Authentication.getUserData();
    if (userData !== undefined && userData !== null) {
      setUser(userData);
    }
  }, []);

  const handleLogout = () => {
    Authentication.logout();
  };
  // console.log(settings);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden sideBar"
        onClose={() => {
          setOpen(false);
          onClose();
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-9 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      aria-label="Close Menu"
                      type="button"
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => {
                        setOpen(false);
                        onClose();
                      }}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-2 bg-gray-900 shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <Dialog.Title className="text-xl text-center font-medium text-white">{user.name}</Dialog.Title>
                  </div>
                  <div className="mt-1 relative flex-1 px-4">
                    {/* Replace with your content */}
                    <div className="flex flex-col  h-full justify-between inset-0 px-4 ">
                      <div className="text-center text-lg text-primary">
                        <div className="text-slate-400">{user.organisation && user.organisation.name}</div>
                      </div>
                      <div className="text-2xl flex flex-col gap-2 align-start mt-2 h-full justify-start items-start  pt-2">
                        <div className="text-2xl tracking-widest text-primary border-b border-gray-700 w-full">Member</div>
                        <NavLink
                          to={`/you/edit`}
                          onClick={() => {
                            setOpen(false);
                            onClose();
                          }}
                          className="ml-2 w-full flex gap-4 items-middle"
                        >
                          <PencilIcon className="h-6 text-primary" /> Edit Your Details
                        </NavLink>

                        <NavLink
                          to={`/you/privacy`}
                          onClick={() => {
                            setOpen(false);
                            onClose();
                          }}
                          className="ml-2 w-full mt-2 flex gap-4 items-middle align-center"
                        >
                          <BiHide className="h-8 text-primary" /> Your Privacy
                        </NavLink>

                        {settings.appAddVehicleSwitch === true && (
                          <NavLink
                            to={`/vehicle/add`}
                            onClick={() => {
                              setOpen(false);
                              onClose();
                            }}
                            className="ml-2 w-full mt-2 flex gap-4 items-middle align-center"
                          >
                            <FaCar className="h-8 text-green-500" /> Add Vehicle
                          </NavLink>
                        )}
                        <NavLink
                          to={`/you/cpd`}
                          onClick={() => {
                            setOpen(false);
                            onClose();
                          }}
                          className="ml-2 w-full mt-2 flex gap-4 items-middle align-center"
                        >
                          <IoIosSchool className="h-8 text-primary" /> Your CPD
                        </NavLink>
                        {/* <div className="mt-4 text-2xl tracking-widest text-primary border-b border-gray-700 w-full">
                          Asset Management
                        </div>
                        <NavLink
                          to={"/assets"}
                          onClick={() => {
                            setOpen(false);
                            onClose();
                          }}
                          className="ml-2 w-full"
                        >
                          View
                        </NavLink> */}
                      </div>
                      <div className="flex flex-col p-2 justify-center">
                        <div className="py-4 text-left text-lg text-white">
                          <div>Build: 4.{_buildNumber}</div>
                        </div>
                        <button aria-label="Logout" className="text-2xl p-2 flex items-center justify-center gap-2 border border-gray-700 rounded-xl my-2" onClick={handleLogout}>
                          <FiLogOut size={"1.0em"} /> Logout
                        </button>
                      </div>
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
export default ChainedContext(SideBar, [
  [
    Settings_Receiver,
    (settings) => {
      return { settings };
    },
  ],
]);
