import React, { useState, useEffect, lazy } from "react";
import User_Receiver from "../context/user_context/User_Receiver";
import Settings_Receiver from "../context/settings_context/Settings_Receiver";
import ChainedContext from "../context/chainedContext";
// import Header from "../components/Header";
import DatePicker from "react-datepicker";
// import CPDCard from "../components/DataField";
import api from "../api";
import toast from "react-hot-toast";

const CPDCard = lazy(() => import("../components/cards/CPDCard"));
const DataField = lazy(() => import("../components/DataField"));
const Header = lazy(() => import("../components/Header"));

function CPDPage({ settings, user, location }) {
  const [cpdRoles, setCpdRoles] = useState([]);
  const [data, setData] = useState({
    date: "",
    minutes: 0,
    details: "",
    type: "",
  });
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [type, setType] = useState("");
  const [records, setRecords] = useState([]);
  const [filtered, setFiltered] = useState([]);
  useEffect(() => {
    if (settings.CPDRoles && user.roles) {
      let roles = settings.CPDRoles.filter((cr) => {
        return user.roles.includes(cr);
      });
      setCpdRoles(roles);
    }
  }, [settings, user]);
  useEffect(() => {
    if (type !== "") {
      // setData({ ...data, type: type });
      let list = records.filter((r) => {
        return r.type === type;
      });
      setFiltered(list);
    } else {
      if (type === "") {
        setFiltered(records);
      }
    }
  }, [type]);
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (location.state && location.state.role) {
      setTimeout(() => {
        setType(location.state.role);
      }, 200);

      // let list = records.filter((r) => {
      //   return r.type === location.state.role;
      // });
      // setFiltered(list);
    }
  }, [location]);

  // console.log("filtered", filtered);
  const getData = () => {
    api.myself.getCPDLogs().then((res) => {
      setRecords(res);
      setFiltered(res);
    });
  };
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const editLog = () => {
    // let formData = data;
    // formData.type = type;
    api.myself.updateCPDLog(data._id, data).then(() => {
      getData();
      setEdit(false);
    });
  };
  const handleDelete = (log) => {
    api.myself.deleteCPDLog(log._id).then(() => {
      getData();
      toast.success("Record deleted.");
    });
  };
  const submitLog = () => {
    let formData = data;
    formData.type = type;
    // console.log("form", formData);
    api.myself.addCPDLog(formData).then(() => {
      getData();
      setAdd(false);
    });
  };
  const editRecord = (e) => {
    setEdit(true);
    setData(e);
    // console.log(e);
  };

  return (
    <div className="w-full p-2">
      <Header title={`Your 12 month CPD Log`} />
      <div className="p-2 flex flex-wrap gap-4 border-b border-gray-700">
        {cpdRoles.map((r) => {
          return (
            <div
              key={r}
              onClick={() => {
                setType(r);
                setData({
                  ...data,
                  type: r,
                });
              }}
              className={` ${
                type === r ? "bg-green-800" : "bg-gray-800"
              }  text-lg shadow shadow-gray-600 rounded-xl py-1 px-2`}
            >
              {r}
            </div>
          );
        })}
        <div
          onClick={() => setType("")}
          className={`${
            type !== "" ? "bg-red-700 " : "bg-green-900 bg-opacity-80"
          } text-lg shadow shadow-gray-600 rounded-xl py-1 px-2`}
        >
          All
        </div>
      </div>
      <div className="py-2 mt-1 text-2xl text-primary text-center border-b border-gray-700">
        {type === "" ? "All Records" : type}
      </div>
      <div className="pt-2 pb-4 text-center mb-2">
        {type !== "" && (
          <button
            onClick={() => {
              setAdd(!add);
              setData({ date: "", minutes: 0, details: "", type: "" });
            }}
            className="p-2 mt-1 mx-auto  text-center rounded-lg bg-green-500 bg-opacity-30 border-green-500 border text-white"
          >
            Create {type} Record
          </button>
        )}
      </div>

      {add || edit ? (
        <div className="bg-gray-900 rounded-xl p-1 border border-primary">
          {edit && (
            <div className="text-center text-2xl text-white p-2">
              Edit this CPD record
            </div>
          )}
          <div className="flex">
            <DataField
              label="Date"
              data={
                <DatePicker
                  onChange={(date) => {
                    setData({ ...data, date: date });
                  }}
                  selected={data.date ? new Date(data.date) : ""}
                  dateFormat="dd-MM-yy"
                  maxDate={new Date()}
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                  className="datePicker bg-gray-900 p-2 w-full rounded-xl border border-gray-700"
                  data-disable-touch-keyboard
                />
              }
            />
            <DataField
              label="Minutes"
              data={
                <input
                  type="number"
                  name="minutes"
                  value={data.minutes}
                  autoComplete="off"
                  placeholder="20"
                  onChange={onChange}
                  className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
                />
              }
            />
          </div>
          <DataField label="Type" data={type || data.type} />
          <DataField
            label="Details"
            data={
              <textarea
                name="details"
                value={data.details}
                autoComplete="off"
                placeholder="what you did"
                onChange={onChange}
                className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
              />
            }
          />
          <div className="flex justify-between gap-4">
            <button
              onClick={() => {
                setEdit(false);
                setAdd(false);
              }}
              className="p-2 my-4 bg-red-700 rounded-lg w-full "
            >
              Cancel
            </button>

            <button
              onClick={add ? submitLog : editLog}
              className="p-2 my-4 bg-green-700 rounded-lg w-full "
            >
              {add ? "Add Record" : "Edit Record"}
            </button>
          </div>
        </div>
      ) : filtered.length ? (
        filtered.map((r) => {
          return (
            <CPDCard
              log={r}
              key={r._id}
              edit={(e) => editRecord(e)}
              passDelete={handleDelete}
            />
          );
        })
      ) : (
        "No records"
      )}
    </div>
  );
}
export default ChainedContext(CPDPage, [
  [
    User_Receiver,
    (user) => {
      return { user };
    },
  ],
  [
    Settings_Receiver,
    (settings) => {
      return { settings };
    },
  ],
]);
