import React from "react";
import BuildNumber from "../_buildNumber";
import TeamSiteLogo from "../assets/teamsite_logo.png";

const LoginScreen = ({ login }) => {
  return (
    <div className="flex flex-col justify-center w-full h-screen loginScreen text-center">
      <div className="flex flex-col h-screen justify-between bg-gray-800 bg-opacity-80">
        <div className="flex flex-col gap-4 justify-between">
          <img
            alt="TeamSite Logo"
            src={TeamSiteLogo}
            className="w-1/2 m-auto border-0 pt-4"
          />
          <div className="pt-6 text-lg text-center">
            The companion web app for TeamSite. <br />
            You will need to have an account to access
          </div>
          <div>Build: 4.{BuildNumber}</div>
          <div className="mt-6">
            <button
              onClick={login}
              aria-label="Login"
              className="w-1/2 m-auto border hover:bg-gray-500 cursor-pointer border-gray-600 p-2 rounded-xl text-white text-2xl"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginScreen;
