import React, { useState, useEffect, lazy } from "react";
import dayjs from "dayjs";
import { FaEnvelope, FaPhone, FaUser } from "react-icons/fa";
import User_Receiver from "../context/user_context/User_Receiver";
import Settings_Receiver from "../context/settings_context/Settings_Receiver";
import ChainedContext from "../context/chainedContext";
import api from "../api";
import VehiclePhoto from "../components/vehiclePhoto";

const BasicMap = lazy(() => import("../components/maps/basicMap"));
const Loader = lazy(() => import("../components/Loader"));
const Header = lazy(() => import("../components/Header"));
const DataField = lazy(() => import("../components/DataField"));
const DataLine = lazy(() => import("../components/DataLine"));
const DataIconLine = lazy(() => import("../components/DataIconLine"));
const CompCard = lazy(() => import("../components/cards/CompCard"));
const CourseCard = lazy(() => import("../components/cards/CourseCard"));
const VettingCard = lazy(() => import("../components/cards/VettingCard"));
const FeesCard = lazy(() => import("../components/cards/FeesCard"));

function YourDetails({ user, history, settings }) {
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [comps, setComps] = useState([]);
  const [courses, setCourses] = useState([]);
  const [memberCourses, setMemberCourses] = useState([]);

  useEffect(() => {
    user._refresh();
    settings._refresh();
    setLoading(false);
    getVehicles();
  }, []);
  useEffect(() => {
    if (user._id) {
      getVehicles();
      getTraining();
    }
  }, [user]);
  useEffect(() => {
    if (settings.courses) {
      let coursesList = settings.courses
        .filter((c) => c.isRequired)
        .map((r) => {
          return {
            name: r.name,
            expires: "",
            validFor: r.valid,
          };
        });
      // console.log("!!!!!", memberCourses);
      if (memberCourses !== undefined) {
        for (let index = 0; index < memberCourses.length; index++) {
          const theRequire = memberCourses[index];
          let reqExists = coursesList.filter((i) => {
            return i.name === theRequire.title;
          });
          // console.log("**", reqExists);
          if (reqExists[0] === undefined) {
            coursesList.push({
              name: theRequire.title,
            });
          }
        }
        coursesList = coursesList.map((i) => {
          let reqExists = memberCourses.filter((r) => {
            return r.title === i.name;
          });
          if (reqExists[0] === undefined) {
            return i;
          }
          return {
            ...i,
            ...reqExists[0],
          };
        });
      }
      setCourses(coursesList);
    }
  }, [settings, memberCourses]);

  const getVehicles = () => {
    api.myself
      .vehicles()
      .then((res) => {
        setVehicles(res);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getTraining = () => {
    if (settings) {
      if (settings.compsSwitch === true) {
        Promise.all([api.training.comps.all()])
          .then((res) => {
            setComps(res[0]);
          })
          .catch((err) => {});
      } else {
        // console.log("here");
        Promise.all([api.training.courses.required(user.centralId)])
          .then((res) => {
            // console.log("courseRes", res);
            setMemberCourses(res[0]);
          })
          .catch((err) => {});
      }
    }
    // Promise.all([
    //   api.training.comps.all(),
    //   api.training.courses.required(user._id),
    // ])
    //   .then((res) => {
    //     setComps(res[0]);
    //     setCourses(res[1]);
    //   })
    //   .catch((err) => {});
  };

  return (
    <div className="w-full ">
      {/* {loading && "Loading..."} */}
      <Header title={"Your Details"} />
      <div className="">
        <DataIconLine label={<FaUser />} data={user.name} />
        <DataIconLine label={<FaPhone />} data={user.phone} />
        <DataIconLine label={<FaEnvelope />} data={user.email} />
        <DataLine label={"Team ID"} data={user.teamId} />
        <DataLine label={"Force #"} data={user.forceId} />
        {/*  */}
        {/* <div className="flex">
          <DataField label="Team ID" data={user.teamId} />
          <DataField label="Force #" data={user.forceId} />
        </div> */}
      </div>
      <div className="p-2 ">
        <div className="text-gray-400 tracking-widest uppercase text-center text-2xl py-2 ">
          Roles
        </div>
        <div className="flex flex-wrap  gap-2">
          {user.roles &&
            user.roles.map((r, i) => {
              let flag = false;
              if (settings.CPDRoles) {
                flag = settings.CPDRoles.includes(r);
              }

              return (
                <div
                  key={i}
                  onClick={() =>
                    flag ? history.push(`/you/cpd`, { role: r }) : {}
                  }
                  className={`bg-gray-800 ${
                    flag && "shadow shadow-primary"
                  } rounded-xl py-1 px-2`}
                >
                  {r}
                </div>
              );
            })}
        </div>
      </div>
      {user.Dash && user.Dash.total > 0 && (
        <>
          <div className="text-gray-400 tracking-widest uppercase text-center text-2xl py-2 ">
            Deployments
          </div>
          <div className="flex">
            <DataField
              label="Last Deployment"
              data={dayjs(user.Dash.lastCallout).format("DD-MM-YY")}
              textColour={" text-3xl tracking-widest"}
            />
            <DataField
              label="Deployments"
              data={user.Dash.total}
              textColour={" text-3xl tracking-widest"}
            />
          </div>
        </>
      )}
      <div className="p-2">
        <div className="text-gray-400 tracking-widest uppercase text-center text-2xl py-2">
          Your Garage
        </div>
        {loading && <Loader />}
        {vehicles.length > 0 &&
          vehicles.map(v => {
            return (
              <div
                className="mb-2 rounded-xl w-full border-4 border-gray-700 cursor-pointer"
                key={v._id}
                onClick={() => history.push(`/vehicle/${v._id}`)}
              >
                <div className="flex">
                  <DataField label="Make" data={v.make} />
                  <DataField label="Model" data={v.model} />
                </div>
                <div className="flex">
                  <DataField label="Registration Number" data={v.regNumber} />
                </div>
                <div className="text-center p-2">
                  <VehiclePhoto vehicle={v} className="mx-auto w-full border-4 border-white" />
                </div>
              </div>
            );
          })}
      </div>
      {settings.compsSwitch ? (
        <div className="p-2">
          <div className="text-gray-400 tracking-widest uppercase text-center text-2xl py-2">
            Comps
          </div>
          <div className="flex flex-col sm:flex-row flex-wrap justify-center gap-2">
            {comps.map((c) => {
              return <CompCard member={user._id} comp={c} key={c._id} />;
            })}
          </div>
        </div>
      ) : (
        <div className="p-2">
          <div className="text-gray-400 tracking-widest uppercase text-center text-2xl py-2 ">
            Required Courses
          </div>
          <div className="flex flex-col sm:flex-row flex-wrap justify-center gap-2">
            {courses !== undefined &&
              courses.map((c, i) => {
                return <CourseCard course={c} key={i} />;
              })}
          </div>
        </div>
      )}
      <div className="p-2">
        <div className="text-gray-400 tracking-widest uppercase text-center text-2xl py-2">
          Vetting
        </div>
        <div className="flex flex-col sm:flex-row flex-wrap justify-center gap-2">
          {user.vetting &&
            user.vetting.map((v, i) => {
              return <VettingCard vetting={v} key={i} />;
            })}
        </div>
      </div>
      {settings.feeSwitch && (
        <div className="p-2">
          <div className="text-gray-400 tracking-widest uppercase text-center text-2xl py-2">
            Fees
          </div>
          <div className="flex flex-col sm:flex-row flex-wrap justify-center gap-2">
            {user.fees && user.fees.length > 0 ? (
              user.fees.map((v, i) => {
                return <FeesCard fee={v} key={i} />;
              })
            ) : (
              <div className="text-center">--</div>
            )}
          </div>
        </div>
      )}
      <div className="p-2">
        <div className="text-gray-400 tracking-widest uppercase text-center text-2xl py-2">
          Address
        </div>
        <div className="flex flex-col justify-center gap-2">
          {user.geoJSON && user.geoJSON.properties ? (
            <div className="">
              <DataField label="Name" data={user.geoJSON.properties.name} />
              <div className="flex ">
                <DataField label="Town" data={user.geoJSON.properties.town} />
                <DataField
                  label="Postcode"
                  data={user.geoJSON.properties.postcode}
                />
              </div>
              {user.geoJSON?.geometry?.coordinates?.length === 2 && (
                <div className="border-2 border-gray-700">
                  <BasicMap
                    mapCenter={user.geoJSON.geometry.coordinates}
                    mapStyle={{ minHeight: "35vh", width: "100%" }}
                    location={user.geoJSON}
                  />
                </div>
              )}
            </div>
          ) : (
            "--"
          )}
        </div>
      </div>
    </div>
  );
}
export default ChainedContext(YourDetails, [
  [
    User_Receiver,
    (user) => {
      return { user };
    },
  ],
  [
    Settings_Receiver,
    (settings) => {
      return { settings };
    },
  ],
]);
