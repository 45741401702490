import React, { useEffect, useState } from "react";
import API from "../api"
import {Authentication} from "../lib/sarstuff_sso_offline";
const VehiclePhoto = ({ vehicle, className }) => {
  const [vehiclePhotoLink, setVehiclePhotoLink] = useState("no_photo");

  useEffect(() => {
    API.vehicles.getPhoto(vehicle._id).then(res => {
      setVehiclePhotoLink(Authentication.getAPIServer() + ".." + res.profileImage);
    })
  }, [vehicle])

  if(vehiclePhotoLink === "no_photo"){
    return null;
  }

  return (
    <img
      src={vehiclePhotoLink}
      alt={vehicle.make}
      className={className}
    />
  );
}
export default VehiclePhoto;