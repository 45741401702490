import React, { useState, useEffect } from "react";
import User_Receiver from "../context/user_context/User_Receiver";
import Settings_Receiver from "../context/settings_context/Settings_Receiver";
import ChainedContext from "../context/chainedContext";
import Header from "../components/Header";
import api from "../api";
import toast from "react-hot-toast";
import ToggleSwitch from "../components/ToggleSwitch";

function PrivacySettings({ user }) {
  const [data, setData] = useState({
    privacy: {
      hidePhone: null,
    },
  });
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (update === true) {
      // console.log("update", data);
      api.myself.update(data).then(() => {
        toast.success("Settings changed");
        setUpdate(false);
      });
    }
  }, [update]);
  useEffect(() => {
    if (user.privacy) {
      setData({
        privacy: {
          hidePhone: user.privacy.hidePhone,
          hideEmail: user.privacy.hideEmail,
          hideAddress: user.privacy.hideAddress,
        },
      });
    }
  }, [user]);

  // console.log(data);
  return (
    <div className="w-full pt-2">
      <Header title={`Your Privacy Settings`} />
      <div className="max-w-xl mx-auto">
        <div className="w-full p-4 text-lg">
          You can hide your email address, phone numbers and actual address from
          team members.
        </div>
        <div className="w-full p-2 text-primary italic ">
          Please note that team administrators will still have access to these
          details.
        </div>

        <div className="p-2 border-t border-gray-700">
          <div className="flex justify-between p-2 text-lg">
            <div>Phone Number</div>
            <div className="flex gap-2 items-middle">
              <ToggleSwitch
                isChecked={data.privacy.hidePhone}
                onChange={() => {
                  setData({
                    ...data,
                    privacy: {
                      ...data.privacy,
                      hidePhone: !data.privacy.hidePhone,
                    },
                  });
                  setUpdate(true);
                }}
              />
              {data.privacy.hidePhone ? (
                <span className="text-red-500 font-bold">Hidden</span>
              ) : (
                <span className="text-green-500 font-bold">Visible</span>
              )}
            </div>
          </div>
          <div className="flex justify-between p-2 text-lg">
            <div>Email</div>
            <div className="flex gap-2 items-middle">
              <ToggleSwitch
                name="email"
                isChecked={data.privacy.hideEmail}
                onChange={() => {
                  setData({
                    ...data,
                    privacy: {
                      ...data.privacy,
                      hideEmail: !data.privacy.hideEmail,
                    },
                  });
                  setUpdate(true);
                }}
              />
              {data.privacy.hideEmail ? (
                <span className="text-red-500 font-bold">Hidden</span>
              ) : (
                <span className="text-green-500 font-bold">Visible</span>
              )}
            </div>
          </div>
          <div className="flex justify-between p-2 text-lg">
            <div>Address</div>
            <div className="flex gap-2 items-middle">
              <ToggleSwitch
                isChecked={data.privacy.hideAddress}
                onChange={() => {
                  setData({
                    ...data,
                    privacy: {
                      ...data.privacy,
                      hideAddress: !data.privacy.hideAddress,
                    },
                  });
                  setUpdate(true);
                }}
              />
              {data.privacy.hideAddress ? (
                <span className="text-red-500 font-bold">Hidden</span>
              ) : (
                <span className="text-green-500 font-bold">Visible</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChainedContext(PrivacySettings, [
  [
    User_Receiver,
    (user) => {
      return { user };
    },
  ],
  [
    Settings_Receiver,
    (settings) => {
      return { settings };
    },
  ],
]);
