import React from "react";
import Logo from "./assets/teamsite_logo.png";

export default class ErrorCatchPage extends React.Component {
  state = {
    isError: false
  };
  componentDidCatch(error, info){
    // Display fallback UI
    this.setState({isError: true});
  }
  render(){
    if (this.state.isError){
      return (
        <div className={"w-full p-2 text-center"}>
          <img src={Logo} className={"mx-auto"} style={{width: 250}} />
          <div className={"mx-10 my-4 p-2 bg-red-900 rounded text-white"}>
            <div className={"text-2xl"}>There seems to be a problem</div>
            <div className={"text-md p-2"}>Oh no, it looks like you have found a bug!</div>
            <div className={"text-md p-2"}>
              Please help us improve the 'TeamSite Mobile App' and report this bug to <a className={"text-blue-400"} href={"mailto:support@sarstuff.co.uk"}>support@sarstuff.co.uk</a> detailing the exact steps you have taken prior to seeing this screen.
            </div>
          </div>
          <button className={"p-2 rounded bg-blue-800 border border-blue-500 text-white"} onClick={() => window.location = "/"}>Reload App</button>
        </div>
      );
    }
    return this.props.children;
  }
}