import React, { useState, useEffect, lazy } from "react";
import { useParams } from "react-router-dom";
import User_Receiver from "../context/user_context/User_Receiver";
import Settings_Receiver from "../context/settings_context/Settings_Receiver";
import ChainedContext from "../context/chainedContext";
// import DataLine from "../components/DataLine";
import DatePicker from "react-datepicker";
import api from "../api";
import toast from "react-hot-toast";
import {
  LockClosedIcon,
  LockOpenIcon,
  PencilIcon,
} from "@heroicons/react/outline";

const Select2 = lazy(() => import("../components/Select2"));
const DataField = lazy(() => import("../components/DataField"));
const Header = lazy(() => import("../components/Header"));

const tyreList = [
  { key: 1, text: "Road", value: "Road" },
  { key: 2, text: "Mud", value: "Mud" },
  { key: 3, text: "Mud/Snow", value: "Mud/Snow" },
  { key: 4, text: "All Terrain", value: "All Terrain" },
];

function EditVehicle({ user }) {
  const [data, setData] = useState({
    make: "",
    model: "",

    seats: "",
    tyres: "",
    doors: "",
    insurance: "",
    towWeight: "",
    colour: "",
    regNumber: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [inputDisabled, setInputDisabled] = useState(true);
  const params = useParams("vehicle_id");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api.vehicles.show(params.vehicle_id).then((res) => {
      setData({
        _id: res._id,
        make: res.make ? res.make : "",
        model: res.model ? res.model : "",
        regNumber: res.regNumber ? res.regNumber : "",
        seats: res.seats ? res.seats : "",
        tyres: res.tyres ? res.tyres : "",
        doors: res.doors ? res.doors : "",
        model: res.model,
        towWeight: res.towWeight,
        insurance: res.insurance ? res.insurance : "",
        colour: res.colour ? res.colour : "",
      });
    });
  };

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setDisabled(false);
  };

  const handleSubmit = () => {
    // console.log(data);
    api.vehicles.update(data._id, data).then(() => {
      toast.success("Details updated");
      getData();
      setDisabled(true);
      setInputDisabled(true);
    });
  };
  // console.log(data);
  return (
    <div className="w-full pt-2">
      <Header title={`Edit ${data.model}`} />
      <div className="px-2 py-3  flex justify-between items-middle align-center">
        <div className="flex-1 p-1">
          {!inputDisabled ? (
            <div className="text-xl flex gap-2 uppercase  text-green-500">
              <LockOpenIcon className="h-6 text-green-500" /> unlocked
            </div>
          ) : (
            <div className="text-xl uppercase  text-red-600 flex gap-2">
              <LockClosedIcon className="h-6 text-red-600" />
              locked
            </div>
          )}
        </div>
        <button
          aria-label="Edit"
          onClick={() => setInputDisabled(!inputDisabled)}
          className={`${
            !inputDisabled && "bg-blue-900"
          } w-30 mx-auto  p-2 bg-gray-800 border border-gray-500 rounded-xl`}
        >
          <PencilIcon className="h-6 text-blue-400" />
        </button>
      </div>

      <div className="flex pt-2">
        <DataField
          label="Make"
          data={
            <input
              disabled={inputDisabled}
              name="make"
              value={data.make}
              autoComplete="off"
              placeholder="land rover, toyota..."
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
        <DataField
          label="Model"
          data={
            <input
              disabled={inputDisabled}
              name="model"
              value={data.model}
              autoComplete="off"
              placeholder="defender, supra..."
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
      </div>
      <div className="flex pt-2">
        <DataField
          label="Registration"
          data={
            <input
              disabled={inputDisabled}
              name="regNumber"
              value={data.regNumber}
              autoComplete="off"
              placeholder="abc123"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
      </div>
      <div className="flex pt-2">
        <DataField
          label="Seats"
          data={
            <input
              disabled={inputDisabled}
              name="seats"
              value={data.seats}
              autoComplete="off"
              placeholder="num seats"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
        <DataField
          label="Doors"
          data={
            <input
              disabled={inputDisabled}
              name="doors"
              value={data.doors}
              autoComplete="off"
              placeholder="num doors"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
      </div>
      <div className="flex pt-2">
        <DataField
          label="Tow Weight"
          data={
            <input
              disabled={inputDisabled}
              name="towWeight"
              value={data.towWeight}
              autoComplete="off"
              placeholder="tow weight"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />
        <DataField
          label="Tyres"
          data={
            <Select2
              field={"tyres"}
              value={data.tyres}
              onChange={onChange}
              options={tyreList ? tyreList : []}
              disabled={inputDisabled}
            />
          }
        />
      </div>
      <div className="flex pt-2">
        <DataField
          label="Colour"
          data={
            <input
              disabled={inputDisabled}
              name="colour"
              value={data.colour}
              autoComplete="off"
              placeholder="colour"
              onChange={onChange}
              className={`p-2 text-center bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
            />
          }
        />

        <DataField
          label="Insurance From"
          data={
            <DatePicker
              onChange={(date) => {
                setData({ ...data, insurance: date });
                setDisabled(false);
              }}
              selected={data.insurance ? new Date(data.insurance) : ""}
              // showTimeSelect
              // timeFormat="HH:mm"
              // timeIntervals={5}
              // showTimeInput
              // shouldCloseOnSelect={false}
              // timeInputLabel="Time:"
              dateFormat="dd-MM-yy"
              maxDate={new Date()}
              nextMonthButtonLabel=">"
              previousMonthButtonLabel="<"
              className="datePicker bg-gray-900 p-2 w-full rounded-xl border border-gray-700"
              data-disable-touch-keyboard
              disabled={inputDisabled}
            />
          }
        />
      </div>
      <div className="p-2"></div>
      <div className="p-2 text-center">
        <button
          aria-label="Update"
          disabled={disabled}
          onClick={handleSubmit}
          className={`${
            disabled && " bg-opacity-30 border-green-900 text-green-900"
          } w-full p-2 bg-green-800 border border-green-500 rounded-xl`}
        >
          Update
        </button>
      </div>
    </div>
  );
}
export default ChainedContext(EditVehicle, [
  [
    User_Receiver,
    (user) => {
      return { user };
    },
  ],
  [
    Settings_Receiver,
    (settings) => {
      return { settings };
    },
  ],
]);
