import axios from "axios";
import Bridge from "../bridge";
import { Authentication } from "..";

function applyHeaders() {
  return {
    headers: {
      Authorization: "Bearer " + Authentication.getAccessToken(),
    },
  };
}
export default {
  auth: {
    exchange_code: (code) => {
      return axios
        .post(Bridge.getAPIServer() + "../oauth/app_code_exchange", {
          code,
        })
        .then((res) => res.data);
    },
    logout: () => {
      return axios.delete(Bridge.getAPIServer() + "../oauth/token", applyHeaders()).then((res) => res.data);
    },
    user_details: (access_token) => {
      // console.log("bang");
      return axios
        .get(Bridge.getAPIServer() + "../oauth/user_details", {
          headers: {
            Authorization: "Bearer " + access_token,
          },
        })
        .then((res) => res.data);
    },
  },
};
