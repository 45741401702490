import React, { useState, lazy } from "react";
import User_Receiver from "../context/user_context/User_Receiver";
import Settings_Receiver from "../context/settings_context/Settings_Receiver";
import ChainedContext from "../context/chainedContext";
import Geosuggest from "react-geosuggest";

import api from "../api";
import toast from "react-hot-toast";
import {
  LockClosedIcon,
  LockOpenIcon,
  PencilIcon,
} from "@heroicons/react/outline";

const DataField = lazy(() => import("../components/DataField"));
const Header = lazy(() => import("../components/Header"));

function EditYourDetails({ user, settings }) {
  const [data, setData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    nok: {
      name: user.nok ? user.nok.name : "",
      relationship: user.nok ? user.nok.relationship : "",
      phone: user.nok ? user.nok.phone : "",
    },
    geoJSON: {
      type: "Feature",
      geometry:
        user.geoJSON && user.geoJSON.geometry ? user.geoJSON.geometry : {},
      properties:
        user.geoJSON && user.geoJSON.properties ? user.geoJSON.properties : {},
    },
  });
  const [disabled, setDisabled] = useState(true);
  const [inputDisabled, setInputDisabled] = useState(true);
  // console.log(user);
  // console.log(settings);
  // console.log(data);
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setDisabled(false);
  };
  const onNoKChange = (e) => {
    setData({
      ...data,
      nok: {
        ...data.nok,
        [e.target.name]: e.target.value,
      },
    });

    setDisabled(false);
  };
  const onSuggestSelect = (suggest) => {
    let coords = [];
    let components = {};
    if (suggest) {
      coords = [suggest.location.lng, suggest.location.lat];
      components = suggest.gmaps.address_components;
    }
    let admin_area = "";
    let postal_town = "";
    let postal_code = "";
    // let name = "";
    let street = "";
    let county = "";
    let town = "";
    for (let component = 0; component < components.length; component++) {
      if (components[component].types[0] === "route") {
        street = components[component].long_name;
      }
      if (components[component].types[0] === "locality") {
        admin_area = components[component].long_name;
      }
      if (components[component].types[0] === "postal_town") {
        postal_town = components[component].long_name;
      }
      if (components[component].types[0] === "administrative_area_level_2") {
        county = components[component].long_name;
      }
      if (components[component].types[0] === "postal_code") {
        postal_code = components[component].long_name;
      }
      if (admin_area) {
        town = admin_area;
      } else {
        town = postal_town;
      }
    }
    if (suggest) {
      setData({
        ...data,
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coords,
          },
          properties: {
            name: suggest.label,
            street: street,
            town: town,
            county: county,
            postcode: postal_code,
            address: suggest.gmaps.formatted_address,
          },
        },
      });
      setDisabled(false);
    }
  };
  const handleSubmit = () => {
    // console.log(data);
    api.myself.update(data).then(() => {
      toast.success("Details updated");
      user._refresh();
      setDisabled(true);
      setInputDisabled(true);
    });
  };
  return (
    <div className="w-full p-2">
      <Header title={"Edit Your Details"} />
      <div className="pt-2  flex justify-between items-middle align-center">
        <div className="flex-1 pt-1">
          {!inputDisabled ? (
            <div className="text-xl flex gap-2 uppercase  text-green-500">
              <LockOpenIcon className="h-6 text-green-500" /> unlocked
            </div>
          ) : (
            <div className="text-xl uppercase  text-red-600 flex gap-2">
              <LockClosedIcon className="h-6 text-red-600" />
              locked
            </div>
          )}
        </div>
        <button
          aria-label="Edit"
          onClick={() => setInputDisabled(!inputDisabled)}
          className={`${
            !inputDisabled && "bg-blue-900"
          } w-30 mx-auto  p-2 bg-gray-800 border border-gray-500 rounded-xl`}
        >
          <PencilIcon className="h-6 text-blue-400" />
        </button>
      </div>

      <div className="p-2">
        <label className="text-primary pl-2">First Name</label>
        <input
          disabled={inputDisabled}
          name="firstName"
          value={data.firstName}
          autoComplete="off"
          placeholder="first name"
          onChange={onChange}
          className={`p-2 bg-gray-900 border border-gray-700 rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg`}
        />
      </div>
      <div className="p-2">
        <label className="text-primary pl-2">Last Name</label>
        <input
          disabled={inputDisabled}
          name="lastName"
          value={data.lastName}
          autoComplete="off"
          placeholder="last name"
          onChange={onChange}
          className={`p-2 bg-gray-900  rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg  border border-gray-700`}
        />
      </div>
      <div className="p-2">
        <label className="text-primary pl-2">Phone</label>
        <input
          disabled={inputDisabled}
          name="phone"
          value={data.phone}
          autoComplete="off"
          placeholder="your mobile number"
          onChange={onChange}
          className={`p-2 bg-gray-900  rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg border border-gray-700`}
        />
      </div>

      <div
        className={`text-2xl text-gray-400 pb-2 pt-2 tracking-widestbg-gray-900 bg-opacity-60 text-mono border-b border-gray-800 text-center`}
      >
        Next of Kin
      </div>
      <div className="p-2">
        <label className="text-primary pl-2">Name</label>
        <input
          disabled={inputDisabled}
          name="name"
          value={data.nok.name}
          autoComplete="off"
          placeholder="nok name"
          onChange={onNoKChange}
          className={`p-2 bg-gray-900  rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg border border-gray-700`}
        />
      </div>
      <div className="p-2">
        <label className="text-primary pl-2">Phone</label>
        <input
          disabled={inputDisabled}
          name="phone"
          value={data.nok.phone}
          autoComplete="off"
          placeholder="nok phone number"
          onChange={onNoKChange}
          className={`p-2 bg-gray-900  rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg border border-gray-700`}
        />
      </div>
      <div className="p-2">
        <label className="text-primary pl-2">Relationship</label>
        <input
          disabled={inputDisabled}
          name="relationship"
          value={data.nok.relationship}
          autoComplete="off"
          placeholder="nok relationship"
          onChange={onNoKChange}
          className={`p-2 bg-gray-900  rounded-xl placeholder-gray-500 block w-full shadow-sm text-lg border border-gray-700`}
        />
      </div>
      <div
        className={`text-2xl text-gray-400 pb-2 pt-2 tracking-widestbg-gray-900 bg-opacity-60 text-mono border-b border-gray-800 text-center`}
      >
        Your Address
      </div>
      <div className="p-2">
        <label className="text-primary pl-2">Add/Change address</label>
        <Geosuggest
          disabled={inputDisabled}
          // ref={(el) => (this._geoSuggest = el)}
          placeholder="123 the street, Molash, Kent"
          inputClassName="geosuggest__input bg-gray-800 rounded-xl"
          country={"GB"}
          onSuggestSelect={onSuggestSelect}
          suggestsClassName="geosuggest__suggests"
          suggestsHiddenClassName="geosuggest__suggests--hidden"
          suggestItemClassName="geosuggest__item"
        />
        <div className="mt-4">
          <DataField label="Name" data={data.geoJSON.properties.name} />
          <div className="flex ">
            <DataField label="Town" data={data.geoJSON.properties.town} />
            <DataField
              label="Postcode"
              data={data.geoJSON.properties.postcode}
            />
          </div>
        </div>
      </div>
      <div className="p-2 text-center">
        <button
          aria-label="Update"
          disabled={disabled}
          onClick={handleSubmit}
          className={`${
            disabled && " bg-opacity-30 border-green-900 text-green-900"
          } w-full p-2 bg-green-800 border border-green-500 rounded-xl`}
        >
          Update
        </button>
      </div>
    </div>
  );
}
export default ChainedContext(EditYourDetails, [
  [
    User_Receiver,
    (user) => {
      return { user };
    },
  ],
  [
    Settings_Receiver,
    (settings) => {
      return { settings };
    },
  ],
]);
