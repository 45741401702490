import React from "react";
import Settings from "./Settings_Context";

export default class SettingsReceiver extends React.Component {
  state = {
    settings: {},
  };
  componentDidMount() {
    this._refreshSettings();
  }
  _refreshSettings = () => {
    this.props.api.settings.all().then((res) => {
      this.setState({
        settings: res,
      });
    });
  };
  render() {
    return (
      <Settings.Provider
        value={{
          _refresh: this._refreshSettings,
          ...this.state.settings,
        }}
      >
        {this.props.children}
      </Settings.Provider>
    );
  }
}
